import React, {useCallback} from 'react';
import OptionElement from "./OptionElement";

/**
 *
 * @param optionsAvailable
 * @param selectedOptions
 * @param toggleSelectedOption
 * @returns {JSX.Element}
 * @constructor
 */
export default function OptionList({ optionsAvailable, selectedOptions, toggleSelectedOption }) {
  return (
    <div>
      <div>
        {optionsAvailable.map((option, index) => (
          <OptionElement
            key={optionsAvailable.length + index}
            isSelected={selectedOptions[option.id] ?? false}
            selectedOptions={selectedOptions}
            onClick={toggleSelectedOption}
            option={option}
          />
        ))}
      </div>
    </div>
  );
}