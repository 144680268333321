import React, {useContext} from 'react';
import {UserContext} from "../../contexts/UserContext";

import { useHistory, useLocation } from 'react-router-dom';

import api from '../../lib/api';
import {useForm} from "react-hook-form";

import { jwtDecode } from "../../lib/jwt-helpers";
import Container from "../../components/Container";

function LoginPage() {
  const { userContext, setUserContext} = useContext(UserContext);
  const { register, handleSubmit } = useForm();

  const history = useHistory();
  const location = useLocation();

  const { from } = location.state || { from: { pathname: "/dashboard" } };

  const onSubmit = async (data) => {
    const response = await api.login(data.email, data.password);
    await localStorage.setItem('jwt-token', response.data.token);

    const jwt = jwtDecode(response.data.token);

    setUserContext({
      ...userContext,
      user: {
        name: jwt.name,
        email: jwt.email,
        roles: jwt.roles,
      }
    });

    history.replace(from);
  }

  return (
    <Container>
      <div className="flex justify-center mt-60">
        <div className="w-full max-w-xs">
          <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={ handleSubmit(onSubmit) }>
            <h1>Login</h1>
            <div className="mb-4 mt-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                Email
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="username" type="email" placeholder="Email"
                {...register("email", { required: true })}
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                Password
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="password" type="password" placeholder="******************"
                {...register("password", { required: true })}
              />
            </div>
            <div className="flex flex-row-reverse">
              <button type="submit" className="py-2 px-4 bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75">Login</button>
            </div>
          </form>
          <p className="text-center text-gray-500 text-xs">
            &copy;2021 Sustainable Pieces. All rights reserved.
          </p>
        </div>
      </div>
    </Container>
  )
}

export default LoginPage;