import axios from 'axios';
import {toastWithMessage} from "./toast-helpers";
import {history} from "../Routes";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Set the AUTH token for any request
instance.interceptors.request.use(config => {
  const token = localStorage.getItem('jwt-token');

  config.headers.Authorization = token ? `Bearer ${token}` : '';

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.data.message) {
    toastWithMessage(error.response.data.message)
  }

  if (error.response.status === 401) {
    history.push('/login');
    localStorage.removeItem('jwt-token');
  }

  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export default instance;