import {Redirect, Route} from "react-router-dom";
import {useContext} from "react";
import {UserContext} from "../contexts/UserContext";

function SuperAdminAuthRoute({component: Component, ...params}) {
  const { userContext } = useContext(UserContext);

  const isAdmin = userContext.user && userContext.user.roles.includes('admin');

  return (
    <Route {...params} render={(props) => isAdmin ?
             <Component {...props} /> :
             <Redirect to={{pathname: '/not-found', state: {from: props.location}}} />
    }
    />
  );
}

export default SuperAdminAuthRoute;