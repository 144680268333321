import StandardForm from "./Forms/StandardForm";
import {toFormFields} from "../lib/functions";
import ProductVariant from "../models/ProductVariant";
import {toast} from "react-toastify";
import {toastWithMessage} from "../lib/toast-helpers";

/**
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export default function PricingForm({ productVariant, setIsLoading }) {
  const onSubmit = async (data) => {
    setIsLoading(true);

    return ProductVariant.store.edit(productVariant.id, data).then(() => {
      toastWithMessage(`${ProductVariant.modelName} updated`);
    }).finally(setIsLoading(false))
  };

  return (
    <div>
      <StandardForm formFields={toFormFields(ProductVariant, "pricing")}
                    onSubmit={onSubmit}
                    model={productVariant}
                    topic="pricing"
      />
    </div>
  );
}
