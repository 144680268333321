import IF from "./Common/IF";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import {useCallback, useState} from "react";
import {isEmpty} from "lodash";
import ChooseProductOptionsContainer from "./ProductVariants/ChooseProductOptionsContainer";
import ProductVariantValuesSideNavItem from "./ProductVariants/ProductVariantValuesSideNavItem";

const Style = {
  aItem: "flex items-center cursor-pointer px-3 py-2 text-sm font-medium text-gray-900 rounded-md hover:border hover:border-green-500 hover:bg-gray-50",
  active: "bg-gray-50 border border-green-500"
}


/**
 *
 * @param children
 * @returns {|null}
 * @constructor
 */
export default function EditorVariantsSideNav({ product, refresh, afterVariantCreated }) {
  const variants = product?.variants;

  const history = useHistory();
  const { url } = useRouteMatch();
  const { id } = useParams();

  const [selectedOptionValues, setSelectedOptionValues] = useState({});

  const onClick = useCallback((model) => {
    history.push(`/products/${product.id}/product-variants/${model.id}`);
  }, [history, url]);

  return(
    <div className="flex-col w-80">
      <nav className="pb-5">
        <IF condition={!isEmpty(variants)}>
          {variants.map((item, index) => (
            <ProductVariantValuesSideNavItem
              onClick={onClick}
              key={index}
              item={item}
              Style={Style}
              activeStyle={parseInt(id) === item.id ? Style.active: ''}
            />
          ))
          }
        </IF>
      </nav>
      <ChooseProductOptionsContainer
        setSelectedOptionValues={setSelectedOptionValues}
        selectedOptionValues={selectedOptionValues}
        productId={product.id}
        afterVariantCreated={afterVariantCreated}
        refresh={refresh}
      />
    </div>
  );

}