import {classNames, toFormFields} from "../../lib/functions";
import React, {useState} from "react";
import {Styles} from "./AttachmentBlock";
import StandardForm from "../Forms/StandardForm";
import Attachment from "../../models/Attachment";
import {toastWithMessage} from "../../lib/toast-helpers";

/**
 *
 * @param attachment
 * @param updateAttachment
 * @param handleDeactivate
 * @returns {JSX.Element}
 * @constructor
 */
export default function AttachmentEditor({ attachment, updateAttachment, handleDeactivate }) {
  const onSubmit = async (data) => {
    await updateAttachment(attachment, data);

    toastWithMessage('Attachment updated')
  };

  return (
    <div className="flex gap-3">
      <div className="w-2/3">
        <img
          src={attachment.large}
          className={classNames(Styles.uploadBlock, Styles.uploadBlockExisting)}
          alt={attachment.file_name}
        />
      </div>
      <div className="w-1/3 h-full">
        <StandardForm
          formFields={toFormFields(Attachment)}
          handleDeactivate={handleDeactivate}
          model={attachment}
          onSubmit={onSubmit}
        />
        <div>
          <label>When cropped:</label>
          <img
            src={attachment.cropped}
            className={classNames('border border-gray-600', Styles.uploadBlock, Styles.uploadBlockExisting)}
            alt={attachment.file_name}
          />
        </div>
      </div>
    </div>
  );
}