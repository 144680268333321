import productTypeEndpoints  from "../lib/endpoints/ProductTypeEndpoints";
import Url from "./Url";

const ProductType = {
  store: productTypeEndpoints,

  type: 'product_type',
  modelName: 'ProductType',
  fields: {
    name: {
      type: 'string',
      length: 30,
      required: true,
      label: 'Name',
    },
    is_featured: {
      type: 'boolean',
      length: 30,
      required: false,
      editable: true,
      hidden: false,
      label: 'Featured',
      style: 'w-full',
    },
    urls: {
      type: 'hasMany',
      required: false,
      contentType: Url,
      hidden: true,
    },
  },
  display(data) {
    return `${data.name}`;
  },
};

export default ProductType;
