import BaseEndpoints from "../lib/endpoints/BaseEndpoints";

const Category = {
  store: new BaseEndpoints('categories'),

  type: 'category',
  fields: {
    name: {
      type: 'string',
      length: 30,
      required: true,
      label: 'Name',
    },
    slug: {
      type: 'string',
      length: 30,
      required: false,
      editable: false,
      label: 'Slug',
    },
  },
  display(data) {
    return `${data.name}`;
  },
};

export default Category;
