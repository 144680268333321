import React, {useState} from 'react';
import SelectSearchBox from "../Inputs/SelectSearchBox";
import {toggleState} from "../../lib/functions";
import Modal from "../Modal";
import EditProductOptionForm from "../ProductVariants/EditProductOptionForm";
import DeleteButton from "./DeleteButton";
import {RiDeleteBinLine} from "react-icons/all";
import ProductOption from "../../models/ProductOption";

const transformToSelectBoxValues = (optionValues) => {
  return optionValues.map((optionValue) => {
    return { value: optionValue.id, label: optionValue.name }
  })
}

/**
 *
 * @param options
 * @param setOptionValues
 * @param selectedOptionValues
 * @param handleDeactivate
 * @returns {JSX.Element}
 * @constructor
 */
export default function OptionValuesList({ options, setOptionValues, selectedOptionValues, handleDeactivate }) {
  const [optionForModal, setOptionForModal] = useState(null);
  const [isOpenEditOptionModal, setIsOpenEditOptionModal] = useState(false);

  const selectOptionValue = (option, optionValueId) => {
    const newSelectedOptionValues =  { ...selectedOptionValues };

    if (optionValueId === null) {
      delete newSelectedOptionValues[option.id];
    } else {
      newSelectedOptionValues[option.id] = optionValueId;
    }

    setOptionValues(newSelectedOptionValues);
  }

  const openEditModal = (option) => {
    setOptionForModal(option);
    setIsOpenEditOptionModal(!isOpenEditOptionModal)
  }

  const deleteOption = (option) => {
    ProductOption.store.delete(option.id).then(() => {
      handleDeactivate();
    })
  }

  return (
    <div>
      <div className="flex flex-col space-y-4">
        {options.map((option, index) => (
          <div key={index} className="">
            <div>
              <label
                htmlFor="name"
                onClick={() => openEditModal(option)}
                className="flex items-center block text-sm font-medium text-blue-700 cursor-pointer">
                <span className="block">{option.name}</span>
              </label>
              <div className="flex">
                <div className="w-full">
                  <SelectSearchBox
                    options={transformToSelectBoxValues(option.values)}
                    defaultOptionIndex={null}
                    value={null}
                    setValue={(value) => {
                      selectOptionValue(option, value)
                    }}
                  />
                </div>
                <DeleteButton
                  className={"flex ml-5"}
                  type="button"
                  icon={RiDeleteBinLine}
                  onClick={() => deleteOption(option) }
                />
              </div>
            </div>
          </div>
        ))}
        <Modal title={"Edit values of Option"} isOpen={isOpenEditOptionModal} toggleIsOpen={toggleState(setIsOpenEditOptionModal, isOpenEditOptionModal)} >
          <EditProductOptionForm
            option={optionForModal}
            handleDeactivate={() => {setIsOpenEditOptionModal(false); handleDeactivate()}} />
        </Modal>
      </div>
    </div>
);
}