import {toFormFields} from "../../lib/functions";
import StandardForm from "../Forms/StandardForm";
import ProductOption from "../../models/ProductOption";

/**
 * @returns {JSX.Element}
 * @constructor
 */
export default function CreateProductOptionForm({ handleDeactivate }) {
  const onSubmit = (data) => {
    return ProductOption.store.add(data).then(handleDeactivate);
  }

  return (
    <>
      <StandardForm formFields={toFormFields(ProductOption)}
                    onSubmit={onSubmit}
                    handleDeactivate={handleDeactivate}
      />

    </>
  );
}