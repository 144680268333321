import React, {useRef} from "react";
import StandardForm from "../../components/Forms/StandardForm";
import Layout from "../../components/Layout";
import Content from "../../components/Content";
import ContentCard from "../../components/ContentCard";
import {toFormFields, toFormFieldsGrouped} from "../../lib/functions";
import DeleteCard from "../../components/DeleteCard";
import Brand from "../../models/Brand";
import AttachmentUploads from "../../components/Attachments/AttachmentUploads";
import {useEditHelpers} from "../../lib/edit-helpers";
import BackButton from "../../components/Common/BackButton";
import Featured from "../../models/Featured";
import AddUrlsForm from "../../components/Urls/AddUrlsForm";
import BottomOverlayBar from "../../components/BottomOverlayBar";
import AttachmentUploadsToField from "../../components/Forms/AttachmentUploadsToField";
import SaveBar from "../../components/Forms/SaveBar";

const modelClass = Brand;

function EditBrandPage() {
  const { data, onSubmit, onDelete, isLoading, handleDeactivate, loadData } = useEditHelpers(modelClass);
  const formRef = useRef();

  const brandFormFields = toFormFields(modelClass);
  const brandFormFieldsGroups = toFormFieldsGrouped(modelClass);

  return (
    <Layout>
      <Content className="edit-brands pb-32">
        {data &&
        <>
          <div className="content-header w-full flex mb-5">
            <BackButton onClick={handleDeactivate}/>
            <h1 className="block">Edit {modelClass.modelName}</h1>
          </div>
          <ContentCard isLoading={isLoading}>
            <StandardForm formFields={brandFormFields}
                          ref={formRef}
                          onSubmit={onSubmit}
                          handleDeactivate={handleDeactivate}
                          model={data} />
          </ContentCard>

          <ContentCard title="URLs" isLoading={isLoading}>
            {formRef.current &&
            <AddUrlsForm
              formRef={formRef}
              model={data}
              ownerModel={Brand}
            />
            }
          </ContentCard>
          <ContentCard title="Brand Logos" isLoading={isLoading}>
            <AttachmentUploadsToField
              ownerModel={modelClass}
              fieldObject={brandFormFieldsGroups.logos[0]}
              model={data}
              refresh={() => loadData(false)}
            />
          </ContentCard>

          <ContentCard title="Image uploads" isLoading={isLoading}>
            <AttachmentUploads ownerModel={modelClass} model={data} refresh={() => loadData(false)} />
          </ContentCard>

          {data.is_featured &&
          <ContentCard title="Featured Images" isLoading={isLoading}>
            <AttachmentUploads ownerModel={Featured} model={data.featured} refresh={() => loadData(false)}/>
          </ContentCard>
          }

          <DeleteCard onDelete={onDelete} />
        </>
        }
      </Content>
      <BottomOverlayBar
        isOpen={true}>
        <SaveBar
          formFields={brandFormFields}
          formRef={formRef}
          model={data}
        />
      </BottomOverlayBar>
    </Layout>
  )
}

export default EditBrandPage;
