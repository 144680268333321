import {classNames} from "../../lib/functions";
import React from "react";

function DeleteButton({ text = null, className, icon = null, onClick, type= "button", color = 'red' }) {
  const defaultClassName = `py-2 items-center flex px-4 border border-gray-200 rounded-lg shadow-md text-white bg-red-600 hover:text-white hover:bg-red-500 focus:outline-none hover:ring-2 hover:ring-green-600 hover:ring-opacity-50`;
  className = classNames(className, defaultClassName);

  return (
    <button
      onClick={onClick}
      type={type}
      className={className}>
      {icon && React.createElement(icon) }
      {text}
    </button>
  );
}

export default DeleteButton;