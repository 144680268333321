import { useCallback } from "react";
import {useLocation} from "react-router-dom";

export const useOnClickRow = (modelData, history, url) => {
  const location = useLocation();

  const onClickRow = useCallback((model) => {
    history.push(`${url}/edit/${model.id}`, { model: model, parentPath: location.pathname} );
  }, [history, url]);

  return { onClickRow };
}
