import React from 'react';
import {classNames} from "../../lib/functions";

export const ButtonStyleType = {
  red: 'text-white bg-red-500 hover:bg-red-700 focus:ring-red-500',
  green: 'text-white bg-green-500 hover:bg-green-700 focus:ring-green-500',
  natural: "border-gray-200 text-gray-600 hover:bg-gray-100",
  disabled: 'text-white bg-green-500 hover:bg-green-700 focus:ring-green-500 cursor-not-allowed'
}

/**
 * @param children
 * @param text
 * @param disabled
 * @param icon
 * @param styleType
 * @param onClick
 * @returns {JSX.Element}
 * @constructor
 */
function Button({ children, text, disabled= false, icon= null, styleType ='green', onClick = () => {}}) {
  const className = classNames(ButtonStyleType[styleType], `
  flex
  items-center
  py-2
  px-4
  border
  rounded-lg
  shadow-md 
  focus:outline-none 
  focus:ring-2
  focus:ring-opacity-75
  `);

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={className}>
      {icon && React.createElement(icon) }
      {text}
      {children}
    </button>
  );
}

export default Button;