/**
 *
 * @param fieldObject
 * @param register
 * @param registerOptions
 * @param className
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
import {classNames, toFormFields} from "../../lib/functions";
import React, {useEffect} from "react";
import {useFieldArray} from "react-hook-form";
import IF from "../Common/IF";
import ActionButton from "../Common/ActionButton";
import { FiPlus, RiDeleteBinLine} from "react-icons/all";
import DeleteButton from "../Common/DeleteButton";

const Styles = {
  input: 'flex',
}

export default function HasManyInput({fieldObject, control, register, className }) {
  const modelClass = fieldObject.contentType;
  const fieldKey = fieldObject.field;

  const formFields = toFormFields(modelClass);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "values"
  });

  useEffect(() => {
    register('values');
  }, []);

  return (
    <>
      <div className="border border-2 mb-2"/>
      {fields.map((field, index) => (
        <div className="mb-2 flex flex-col w-ful gap-2">
          {formFields.map((fieldObject, formIndex ) => (
            <IF key={'field' + formIndex} condition={!fieldObject.hidden}>
              <div>
                <label className="uppercase text-xs">{fieldObject.label}<span>{ fieldObject.required ? '*': ''}</span></label>
                <div key={index} className="flex w-full">
                  <input type="text"
                         key={'input' + formIndex + index}
                         name={`${fieldKey}.${index}.${fieldObject.field}`}
                         placeholder={fieldObject.placeholder}
                         disabled={ fieldObject.editable === false }
                         className={classNames(className, Styles.input)}
                         {...register(`${fieldKey}.${index}.${fieldObject.field}`)}
                  />
                  <DeleteButton
                    className={"ml-5"}
                    type="button"
                    icon={RiDeleteBinLine}
                    onClick={ () => remove(index)  }
                  />
                </div>
              </div>
            </IF>
          ))}
      </div>
      ))}
      <div className="mt-4 w-full">
        <ActionButton
          type="button"
          icon={FiPlus}
          text={"Add a new value"}
          onClick={ () => append({ name: '' })  } />
      </div>
    </>
  )
}