import React from "react";
import {Link, Route, Switch} from "react-router-dom";

import Layout from "../../components/Layout";
import SubmitButton from '../../components/Common/SubmitButton';
import Content from "../../components/Content";
import Table from '../../components/Table';
import EditProductTypePage from "./EditProductTypePage";
import AddProductTypePage from "./AddProductTypePage";
import {usePagination} from "../../lib/pagination-helpers";
import {useOnClickRow} from "../../lib/table-helpers";
import ProductType from "../../models/ProductType";

/**
 * @returns {JSX.Element}
 * @constructor
 */
function ListProductTypePage() {
  const { data, path, url, history, paginationInfo } = usePagination(ProductType);
  const { onClickRow } = useOnClickRow(data, history, url);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      // {
      //   Header: 'action',
      //   accessor: (value) => {
      //     return value;
      //   },
        // Cell: ({value}) => (
        //   <Link className="hover:text-gray-500"
        //     to={{
        //       pathname:`${url}/edit/${value.id}`,
        //       state: { model: value }
        //     }}>Edit</Link>
        // )
      // },
    ],[]
  )

  return (
    <Layout>
      <Content className="list">
        <div>
          <div className="w-full content-header flex justify-between mb-5">
            <h1 className="block">Product Types</h1>
            <div className="actions float-right">
              <Link to={`${url}/add/`}>
                <SubmitButton className='add-new-button' text={'Add new'} />
              </Link>
            </div>
          </div>
          <div className="w-full">
            <Table data={data} isLoading={data === null} onClickRow={onClickRow} paginationInfo={paginationInfo} columns={columns} />
          </div>
        </div>
      </Content>

      <Switch>
        <Route path={`${path}/edit/:productTypeId`}>
          <EditProductTypePage />
        </Route>
        <Route path={`${path}/add`}>
          <AddProductTypePage />
        </Route>
      </Switch>

    </Layout>
  )
}

export default ListProductTypePage;