import {Link, useLocation} from "react-router-dom";
import {
  AiFillCustomerService,
  BiCollection,
  AiOutlineTrademarkCircle,
  CgOptions,
  FiBox,
  IoShirtOutline, AiFillHdd
} from "react-icons/all";
import React from "react";
import {Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader} from 'react-pro-sidebar';

const menuItems = [
  {
    label: 'Dashboard',
    link: '/dashboard',
    icon: <FiBox size={25}/>,
  },
  {
    label: 'Product Types',
    link: '/product-types',
    icon: <AiFillHdd size={25}/>,
  },
  {
    label: 'Product Options',
    link: '/product-options',
    icon: <CgOptions size={25}/>,
  },
  {
    label: 'Collections',
    link: '/collections',
    icon: <BiCollection size={25}/>,
  },
  {
    label: 'Products',
    link: '/products',
    icon: <IoShirtOutline size={25}/>,
  },
  {
    label: 'Customers',
    link: '/customers',
    icon: <AiFillCustomerService size={25}/>,
  },
  {
    label: 'Brand',
    link: '/brands',
    icon: <AiOutlineTrademarkCircle size={25}/>,
  },
];

export default function NavSidebar() {
  const location = useLocation();

  return (
    <div className="min-h-screen h-full z-30">
      <ProSidebar className="min-h-screen h-full">
        <SidebarHeader>
        </SidebarHeader>
        <SidebarContent className="min-h-screen h-full">
          <Menu iconShape="square">
            { menuItems.map((menuItem, index) => (
              <MenuItem key={index} active={location.pathname === menuItem.link} icon={menuItem.icon}>{menuItem.label}
                <Link to={menuItem.link} />
              </MenuItem>
            ))
            }
          </Menu>
        </SidebarContent>
        <SidebarFooter>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
}