import React, {useRef} from "react";
import StandardForm from "../../components/Forms/StandardForm";
import Layout from "../../components/Layout";
import Content from "../../components/Content";
import ContentCard from "../../components/ContentCard";
import {toFormFields} from "../../lib/functions";
import DeleteCard from "../../components/DeleteCard";
import SelectAttributeList from "../../components/Attributes/SelectAttributeList";
import ProductType from "../../models/ProductType";
import {useEditHelpers} from "../../lib/edit-helpers";
import BackButton from "../../components/Common/BackButton";
import AddUrlsForm from "../../components/Urls/AddUrlsForm";
import AttachmentUploads from "../../components/Attachments/AttachmentUploads";
import Featured from "../../models/Featured";
import BottomOverlayBar from "../../components/BottomOverlayBar";
import SaveBar from "../../components/Forms/SaveBar";

/**
 * @returns {JSX.Element}
 * @constructor
 */
function EditProductTypePage() {
  const { data: productTypeData, onSubmit, handleDeactivate, onDelete, isLoading, refresh } = useEditHelpers(ProductType);

  const formRef = useRef();
  const formFields = toFormFields(ProductType);

  return (
    <Layout>
      <Content className="edit-product-type" isLoading={isLoading}>
        <div>
          <div className="content-header w-full flex items-center mb-5">
            <BackButton onClick={handleDeactivate}/>
            <h1 className="block">Edit Variant</h1>
            <span className="text-base ml-2">{productTypeData && productTypeData?.name}</span>
          </div>
          {productTypeData &&
          <>
            <ContentCard>
              <StandardForm formFields={toFormFields(ProductType)}
                            onSubmit={onSubmit}
                            handleDeactivate={handleDeactivate}
                            model={productTypeData}
                            ref={formRef}
                            hideSaveButtons={true}
              />
            </ContentCard>

            <ContentCard title="URLs" isLoading={isLoading}>
              {formRef.current &&
              <AddUrlsForm formRef={formRef}
                           model={productTypeData}
                           ownerModel={ProductType}
              />
              }
            </ContentCard>

            <ContentCard title="Image uploads" isLoading={isLoading}>
              <AttachmentUploads ownerModel={ProductType} model={productTypeData} refresh={refresh} />
            </ContentCard>

            {productTypeData.is_featured &&
            <ContentCard title="Featured Images" isLoading={isLoading}>
              <AttachmentUploads ownerModel={Featured} model={productTypeData.featured} refresh={refresh} />
            </ContentCard>
            }

            {/*<ContentCard>*/}
              {/*<SelectAttributeList forModel={ProductType} />*/}
            {/*</ContentCard>*/}
            <DeleteCard onDelete={onDelete} />

            <BottomOverlayBar
              isOpen={true}>
              <SaveBar
                formFields={formFields}
                formRef={formRef}
                model={productTypeData}
              />
            </BottomOverlayBar>
          </>
          }
        </div>
      </Content>
    </Layout>
  );
}

export default EditProductTypePage;
