import React, {useCallback, useEffect, useState} from "react";
import {Link, Route, Switch, useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";

import Layout from "../../components/Layout";
import SubmitButton from '../../components/Common/SubmitButton';

import api from '../../lib/api';
import Content from "../../components/Content";
import Table from '../../components/Table';
import EditCustomerPage from "./EditCustomerPage";
import AddCustomerPage from "./AddCustomerPage";
import ProductVariant from "../../models/ProductVariant";
import Product from "../../models/Product";

/**
 * @returns {JSX.Element}
 * @constructor
 */
function ListCustomerPage() {
  const [ customers, setCustomers] = useState(null);
  const { path, url } = useRouteMatch();

  const location = useLocation();

  useEffect(function() {
    api.customers.findAll().then((response) => {
      setCustomers(response.data.data);
    })
  }, [location]);


  const history = useHistory();
  const onCLickRow = useCallback((model) => {
    history.push(`${url}/edit/${model.id}`, { model: model} );
  }, [url]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'UserId',
        accessor: 'user.id',
      },
      {
        Header: 'Name',
        accessor: 'user.firstname',
      },
      {
        Header: 'Lastname',
        accessor: 'user.lastname',
      },
      {
        Header: 'Email',
        accessor: 'user.email',
      },
      {
        Header: 'action',
        accessor: (value) => {
          return value;
        },
        Cell: ({value}) => (
          <Link className="hover:text-gray-500"
            to={{
              pathname:`${url}/edit/${value.id}`,
              state: { model: value }
            }}>Edit</Link>
        )
      },
    ],[]
  )

  return (
    <Layout>
      <Content className="list">
        <div>
          <div className="w-full content-header flex justify-between mb-5">
            <h1 className="block">Customers</h1>
            <div className="actions float-right">
              <Link to={`${url}/add/`}>
                <SubmitButton className='add-new-button' text={'Add new'} />
              </Link>
            </div>
          </div>
          <div className="w-full">
            <Table onClickRow={onCLickRow} isLoading={customers === null} data={customers} columns={columns} />
          </div>
        </div>
      </Content>

      <Switch>
        <Route path={`${path}/edit/:customerId`}>
          <EditCustomerPage />
        </Route>
        <Route path={`${path}/add`}>
          <AddCustomerPage />
        </Route>
      </Switch>

    </Layout>
  )
}

export default ListCustomerPage;