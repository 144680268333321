import Layout from "../../components/Layout";
import Content from "../../components/Content";

function SettingsPage() {
  return (
    <Layout>
      <Content>
        <div>
          <h1 className="block font-light">Settings</h1>
        </div>
      </Content>

    </Layout>
  )
}

export default SettingsPage;