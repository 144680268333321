import productOptionValuesEndpoints from "../lib/endpoints/ProductOptionValueEndpoints";

const ProductOptionValue = {
  store: productOptionValuesEndpoints,

  type: 'product_option_values',
  modelName: 'ProductOptionValue',
  fields: {
    product_option_id: {
      type: 'number',
      length: 30,
      hidden: true,
      style: 'w-full',
    },
    name: {
      type: 'string',
      length: 30,
      required: true,
      label: 'Name',
      style: 'w-full',
      placeholder: 'e.g., Red',
    },
  },
  display(data) {
    return `${data.name}`;
  },
};

export default ProductOptionValue;
