import attributeEndpoints  from "../lib/endpoints/AttributeEndpoints";
import {BiArrowToTop, GrStatusGood} from "react-icons/all";

const Attachment = {
  store: attributeEndpoints,

  type: 'attachments',
  fields: {
    name: {
      type: 'string',
      length: 30,
      required: true,
      label: 'Name',
      style: 'w-full',
    },
    crop_type: {
      type: 'select',
      required: false,
      label: 'Crop type',
      style: 'w-full',
      options: [
        {label: 'Top', value: 'crop_top', icon: BiArrowToTop},
        {label: 'Center', value: 'crop_center', icon: GrStatusGood},
        {label: 'Bottom', value: 'crop_bottom', icon: GrStatusGood},
      ],
    }
  },
  display(data) {
    return `${data.name}`;
  },
};

export default Attachment;
