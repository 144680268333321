import React, {useState} from "react";
import api from '../../lib/api';
import {useHistory, useLocation} from "react-router-dom";
import Layout from "../../components/Layout";
import Content from "../../components/Content";
import ContentCard from "../../components/ContentCard";
import StandardForm from "../../components/Forms/StandardForm";

import Product from "../../models/Product";
import { toFormFields} from "../../lib/functions";
import IF from "../../components/Common/IF";
import AddProductOptionsContainer from "../../components/ProductVariants/AddProductOptionsContainer";
import BackButton from "../../components/Common/BackButton";

const cardActions = () => {
  return (
    <span>action</span>
  );
};

function AddProductsPage() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const handleDeactivate = () => {
    history.push(`/products`);
  }

  const onSubmit = async (data) => {
    setIsLoading(true);

    return api.products.add(data).then((response) => {
      const productId = response.data.id;

      history.push(`/products/edit/${productId}`, { parentPath: location.pathname} );
    }).finally(() => {
      setIsLoading(false);
    })
  }

  return (
    <Layout>
      <Content className="add" isLoading={isLoading}>
        <div>
          <div className="content-header w-full flex mb-5">
            <BackButton onClick={handleDeactivate}/>
            <div>
              <h1 className="block">Add Product</h1>
            </div>
          </div>
          <ContentCard isLoading={isLoading}>
            <StandardForm formFields={toFormFields(Product)}
                          onSubmit={onSubmit}
            >
            </StandardForm>
          </ContentCard>
          <ContentCard title="Variants" isLoading={isLoading} cardActions={cardActions}>
            <IF condition={true}>
              <AddProductOptionsContainer />
            </IF>
          </ContentCard>
        </div>
      </Content>
    </Layout>
  );
}

export default AddProductsPage;