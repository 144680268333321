import Url from "./Url";
import BaseEndpoints from "../lib/endpoints/BaseEndpoints";

const Collection = {
  store: new BaseEndpoints('collections'),

  type: 'collections',
  modelName: 'Collection',
  fields: {
    name: {
      type: 'string',
      length: 30,
      label: 'Name',
    },
    is_featured: {
      type: 'boolean',
      length: 30,
      required: false,
      editable: true,
      hidden: false,
      label: 'Featured',
      style: 'w-full',
    },
    urls: {
      type: 'hasMany',
      required: false,
      contentType: Url,
      hidden: true,
    },
    title: {
      type: 'richText',
      length: 512,
      required: false,
      editable: true,
      label: 'Title',
      style: 'w-full',
    },
    description: {
      type: 'richText',
      length: 512,
      required: false,
      editable: true,
      label: 'Description',
      style: 'w-full',
    },
  },
  display(data) {
    return `${data.name}`;
  },
};

export default Collection;
