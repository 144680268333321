import {classNames} from "../lib/functions";
import { AiOutlineCloudUpload } from  "react-icons/all";
import ProgressBar from "./Common/ProgressBar";
import Loader from "./Loader";

const Styles = {
  uploadBlock: 'relative h-50 w-full hover:bg-gray-100 cursor-pointer',
  uploadBlockNew: 'h-40 w-40 bg-gray-100 hover:bg-gray-200 cursor-pointer flex justify-center items-center border-dashed border-2 border-gray-200',
  uploadBlockExisting: 'bg-gray-200 hover:bg-gray-100 cursor-pointer',
  input: "h-full w-full bg-blue opacity-0 cursor-pointer",
};

/**
 *
 * @param uploadedFileCount
 * @param totalFilesUploadingCount
 * @param setAttachment
 * @returns {JSX.Element}
 * @constructor
 */
export default function AttachmentUploadBlock( { uploadedFileCount, totalFilesUploadingCount, setAttachment }) {
  return (
    <>
      <div className={classNames(Styles.uploadBlock, Styles.uploadBlockNew)}>
        {totalFilesUploadingCount === 0 &&
        <>
          <div className="cursor-pointer absolute">
            <div>
              <div className="flex justify-center">
                <AiOutlineCloudUpload className="flex h-10 w-10"/>
              </div>
              <span className="text-center">Upload here</span>
            </div>
          </div>
          <input
            onChange={setAttachment}
            type="file"
            multiple className={Styles.input}
            name=""
          />
        </>
        }
        {totalFilesUploadingCount > 0 &&
        <div className="flex flex-wrap justify-center" >
          <div>
            <ProgressBar
              processed={uploadedFileCount}
              total={totalFilesUploadingCount}
            />
          </div>
          <div>
            <Loader/>
          </div>

        </div>
        }

      </div>
    </>
  );
}