import React, {useRef} from "react";
import Layout from "../../components/Layout";
import Content from "../../components/Content";
import ContentCard from "../../components/ContentCard";
import DeleteCard from "../../components/DeleteCard";
import {useEditHelpers} from "../../lib/edit-helpers";
import AttachmentUploads from "../../components/Attachments/AttachmentUploads";
import BackButton from "../../components/Common/BackButton";
import Collection from "../../models/Collection";
import Product from "../../models/Product";
import BottomOverlayBar from "../../components/BottomOverlayBar";
import SaveBar from "../../components/Forms/SaveBar";
import {toFormFields} from "../../lib/functions";
import StandardForm from "../../components/Forms/StandardForm";
import AddUrlsForm from "../../components/Urls/AddUrlsForm";
import Featured from "../../models/Featured";

function EditCollections() {
  const { data: collectionData, onSubmit, handleDeactivate, onDelete, isLoading, refresh } = useEditHelpers(Collection);
  const formRef = useRef();

  const formFields = toFormFields(Collection);

  return (
    <Layout>
      <Content className="edit-collections" isLoading={isLoading}>
        <div className="content-header w-full flex items-center mb-5">
          <BackButton onClick={handleDeactivate}/>
          <h1 className="block">Edit Variant</h1>
          <span className="text-base ml-2">{collectionData && collectionData?.name}</span>
        </div>

        {collectionData &&
        <>
          <ContentCard>
            <StandardForm formFields={formFields}
                          hideSaveButtons={true}
                          ref={formRef}
                          onSubmit={onSubmit}
                          handleDeactivate={handleDeactivate}
                          model={collectionData}
            />
          </ContentCard>

          <ContentCard title="URLs" isLoading={isLoading}>
            {formRef.current &&
            <AddUrlsForm formRef={formRef} model={collectionData} ownerModel={Collection}/>
            }
          </ContentCard>

          <ContentCard title="Image uploads" isLoading={isLoading}>
            <AttachmentUploads ownerModel={Collection} model={collectionData} refresh={refresh} />
          </ContentCard>

          {collectionData.is_featured &&
          <ContentCard title="Featured Images" isLoading={isLoading}>
            <AttachmentUploads ownerModel={Featured} model={collectionData.featured} refresh={refresh} />
          </ContentCard>
          }
          <DeleteCard onDelete={onDelete}/>
          <BottomOverlayBar
            isOpen={true}>
            <SaveBar
              formFields={formFields}
              formRef={formRef}
              model={collectionData}
            />
          </BottomOverlayBar>
        </>
        }
      </Content>
    </Layout>
  );
}

export default EditCollections;
