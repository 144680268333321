import React from 'react';
import Modal from "../Modal";
import Button from "./Button";
import {Styles} from "../Forms/StandardForm";

/**
 * @param isOpen
 * @param setIsOpen
 * @param doAction
 * @returns {JSX.Element}
 * @constructor
 */
export default function OnDeleteConfirmPrompt({ isOpen, setIsOpen, doAction }) {
  return (
    <Modal title={"Are you sure you want to delete this resource?"} isOpen={isOpen}>
      <div className="font-xs text-gray-700">
        <p>Deleting is permanent.</p>
      </div>
      <div className={Styles.actions}>
        <Button
          onClick={() => setIsOpen(false)}
          styleType='natural'
          className={Styles.cancel}
        >Cancel</Button>
        <Button
          type="submit"
          styleType='red'
          bgColor={'red-500'}
          hoverColor={'red-700'}
          onClick={doAction}
        >Yes I am sure!</Button>
      </div>
    </Modal>
  );
}