import React, {useCallback, useEffect, useState} from "react";
import Layout from "../../components/Layout";
import Content from "../../components/Content";
import ContentCard from "../../components/ContentCard";
import DeleteCard from "../../components/DeleteCard";
import {useEditHelpers} from "../../lib/edit-helpers";
import ProductVariant from "../../models/ProductVariant";
import PricingForm from "../../components/PricingForm";
import ProductIdentifiersForm from "../../components/ProductIdentifiersForm";
import InventoryForm from "../../components/InventoryForm";
import IF from "../../components/Common/IF";
import {isEmpty} from "lodash";
import AttachmentUploads from "../../components/Attachments/AttachmentUploads";
import Product from "../../models/Product";
import BackButton from "../../components/Common/BackButton";
import EditorVariantsSideNav from "../../components/EditorVariantsSideNav";
import {useParams} from "react-router-dom";

function EditProductVariantPage() {
  const { data, isLoading, setIsLoading, loadData, refresh, history } = useEditHelpers(ProductVariant);
  const [product, setProduct] = useState(null);
  const {productId} = useParams();

  useEffect(() => {
    if (data) {
      Product.store.findById(productId).then((response) => {
        setProduct(response.data);
      });
    }
  }, [data]);

  const goBack = useCallback(() => {
    history.push(`/products/edit/${productId}`);
  }, [history, data]);

  const onDelete = async () => {
    return ProductVariant.store.delete(data.id).then(() => {
      Product.store.findById(productId, false).then((response) => {
        history.replace(`/products/${productId}/product-variants/${response.data.variant.id}`);

        // scroll up after delete.
        window.scrollTo(0, 0);
      });
    })
  }
  
  const afterVariantCreated = (newVariant) => {
    return Product.store.findById(productId, false).then((response) => {
      setProduct(response.data);
    }).then(() => {
      history.push(`/products/${productId}/product-variants/${newVariant.id}`);
    });
  }

  return (
    <Layout>
      <Content className="edit-product-variant" isLoading={isLoading}>
        <IF condition={!isEmpty(data) && !isEmpty(product)}>
          <div>
            <div className="content-header w-full flex items-center mb-5">
              <BackButton onClick={goBack}/>
              <h1 className="block">Edit Variant</h1>
              <span className="text-base ml-2">{product?.name}</span>
              {/*<div className="actions float-right"></div>*/}
            </div>
            <div className="flex">
              <div className="flex">
                <EditorVariantsSideNav product={product} refresh={loadData} afterVariantCreated={afterVariantCreated}/>
              </div>
              <div className="flex">
                <div>
                  <ContentCard title="Pricing">
                    <PricingForm productVariant={data} setIsLoading={setIsLoading}/>
                  </ContentCard>

                  <ContentCard title="Product Identifiers">
                    <ProductIdentifiersForm productVariant={data} setIsLoading={setIsLoading}/>
                  </ContentCard>

                  <ContentCard title="Inventory">
                    <InventoryForm productVariant={data} setIsLoading={setIsLoading}/>
                  </ContentCard>

                  <ContentCard title="Image uploads" isLoading={isLoading}>
                    <AttachmentUploads ownerModel={ProductVariant} model={data} refresh={refresh}/>
                  </ContentCard>

                  <DeleteCard onDelete={onDelete}/>
                </div>
              </div>
            </div>
          </div>
        </IF>
      </Content>
    </Layout>
  );
}

export default EditProductVariantPage;
