import {createPortal} from 'react-dom';
import {useState} from "react";
import ModelContent from "./ModelContent";

/**
 *
 * @param children
 * @param title
 * @param isOpen
 * @param toggleIsOpen
 * @returns {|null}
 * @constructor
 */
export default function BottomOverlayBar({ children, title, isOpen = false, toggleIsOpen = {} }) {
  const [ container ] = useState(() => {
    return document.getElementById('root');
  });

  if (!isOpen) return null

  return createPortal(
      <div className="fixed z-50 shadow-2xl bottom-0 h-32 w-full">
        <ModelContent title={title}>
          {children}
        </ModelContent>
      </div>,
    container)
}