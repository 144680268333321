import Url from "./Url";
import {AiOutlineEyeInvisible, GrStatusGood} from "react-icons/all";
import BaseEndpoints from "../lib/endpoints/BaseEndpoints";

const Brand = {
  store: new BaseEndpoints('brands'),

  type: 'brands',
  modelName: 'Brand',
  searchField: 'name',
  fields: {
    name: {
      type: 'string',
      length: 30,
      required: true,
      label: 'Name',
    },
    slogan: {
      type: 'richText',
      length: 512,
      required: false,
      editable: true,
      label: 'Slogan',
      style: 'w-full',
    },
    bio: {
      type: 'richText',
      length: 512,
      required: false,
      editable: true,
      label: 'Bio',
      style: 'w-full',
    },
    logos: {
      type: 'fileUpload',
      required: false,
      hidden: true,
      editable: true,
      label: 'Logo',
      style: 'w-full',
    },
    story: {
      type: 'richText',
      length: 512,
      required: false,
      editable: true,
      label: 'Brand story',
      style: 'w-full',
    },
    description: {
      type: 'richText',
      length: 512,
      required: false,
      editable: true,
      label: 'Brand Description',
      style: 'w-full',
    },
    free_field_1: {
      type: 'richText',
      length: 512,
      required: false,
      editable: true,
      label: 'Free field 1',
    },
    free_field_2: {
      type: 'richText',
      length: 512,
      required: false,
      editable: true,
      label: 'Free field 2',
    },
    free_field_3: {
      type: 'richText',
      length: 512,
      required: false,
      editable: true,
      label: 'Free field 3',
    },
    is_featured: {
      type: 'boolean',
      length: 30,
      required: false,
      editable: false,
      label: 'Featured',
      style: 'w-full',
    },
    urls: {
      type: 'hasMany',
      required: false,
      contentType: Url,
      hidden: true,
    },
    status: {
      type: 'select',
      length: 30,
      required: false,
      hidden: true,
      label: 'Visibility',
      defaultOptionIndex: 0,
      options: [
        {label: 'Published', value: 'published', icon: GrStatusGood},
        {label: 'Draft', value: 'draft', icon: AiOutlineEyeInvisible},
      ],
    },

  },
  display(data) {
    return `${data.name}`;
  },
};

export default Brand;
