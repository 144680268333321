import React, {useRef} from "react";
import StandardForm from "../../components/Forms/StandardForm";
import Layout from "../../components/Layout";
import Content from "../../components/Content";
import ContentCard from "../../components/ContentCard";
import {toFormFields} from "../../lib/functions";
import DeleteCard from "../../components/DeleteCard";
import Customer from "../../models/Customer";
import {useEditHelpers} from "../../lib/edit-helpers";
import User from "../../models/User";
import BottomOverlayBar from "../../components/BottomOverlayBar";
import SaveBar from "../../components/Forms/SaveBar";
import BackButton from "../../components/Common/BackButton";

function EditCustomerPage() {
  const { onDelete, handleDeactivate, data, onSubmit, isLoading } = useEditHelpers(Customer);

  const formRef = useRef();

  return (
    <Layout>
      <Content className="edit-customers pb-32" isLoading={isLoading}>
        <div>
          <div className="content-header w-full flex justify-between mb-5">
            <div className="flex">
              <BackButton onClick={handleDeactivate}/>
              <h1 className="block">Edit Customer</h1>
            </div>
          </div>
          {data &&
          <>
            <ContentCard>
              <StandardForm formFields={toFormFields(User)}
                            onSubmit={onSubmit}
                            hideSaveButtons={true}
                            handleDeactivate={handleDeactivate}
                            model={data.user}
              />
            </ContentCard>
            <DeleteCard onDelete={onDelete} />
          </>
          }
        </div>
      </Content>
      <BottomOverlayBar isOpen={true}>
        <SaveBar model={data} formRef={formRef} />
      </BottomOverlayBar>
    </Layout>
  );
}

export default EditCustomerPage;
