import BaseEndpoints from "../lib/endpoints/BaseEndpoints";
import User from "./User";

const Customer = {
  store: new BaseEndpoints('customers'),

  type: 'customers',
  fields: {
    user: {
      type: 'belongsToInline',
      length: 30,
      contentType: User,
      label: 'User',
      style: 'w-full',
    },
  },
  display(data) {
    return `${data.name}`;
  },
};

export default Customer;
