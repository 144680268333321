import axios from "../axios";

export default class BaseEndpoints {
  resource = null;
  baseUrl = null;

  cachedObjects = {}
  cachedObjectsTimestamps = {}

  constructor(resource) {
    this.resource = resource;
    this.baseUrl = process.env.REACT_APP_API_URL;
  }

  findByIds(ids, fromCache = true) {
    const params = {
      ids: ids.join(','),
    }

    return axios.get(`${this.baseUrl}/api/1/${this.resource}`, { params } ).then((response) => {
      this.__addToCache(response.data);
      return response;
    })
  }
  /**
   * @returns {Promise<AxiosResponse<any>>}
   */
  findById(id, fromCache = true) {
    if (fromCache) {
      const cachedValue = this.__getFromCache(id);

      if (cachedValue) {
        return new Promise(resolve => {
          resolve({data: cachedValue});
        });
      }
    }

    return axios.get(`${this.baseUrl}/api/1/${this.resource}/${id}`).then((response) => {
      this.__addToCache(response.data);
      return response;
    })
  }

  /**
   * @returns {Promise<AxiosResponse<any>>}
   */
  findAll(options = {}) {
    return axios.get(`${this.baseUrl}/api/1/${this.resource}`, options);
  }

  /**
   * @returns {Promise<AxiosResponse<any>>}
   */
  findAllFor(id, owner, options = {}) {
    return axios.get(`${this.baseUrl}/api/1/${this.resource}/${id}/${owner}`, options);
  }

  /**
   *
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  add(data) {
    return axios.post(`${this.baseUrl}/api/1/${this.resource}`, data);
  }

  /**
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  get(params = {}) {
    return axios.get(`${this.baseUrl}/api/1/${this.resource}`, { params });
  }

  /**
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  edit(id, data) {
    return axios.put(`${this.baseUrl}/api/1/${this.resource}/${id}`, data).then((response) => {
      this.__addToCache(response.data)
      return response.data;
    });
  }

  /**
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  delete(id) {
    return axios.delete(`${this.baseUrl}/api/1/${this.resource}/${id}`).then(() => {
      this.__deleteFromCache(id);
    });
  }

  __clearCache() {
    this.cachedObjectsTimestamps = {};
    this.cachedObjects = {};
  }

  __deleteFromCache(id) {
    delete this.cachedObjectsTimestamps[id];
    delete this.cachedObjects[id];
  }

  __addToCache(data) {
    if (data && data.id) {
      this.cachedObjectsTimestamps[data.id] = {id: data.id, timestamp: new Date().getTime()};
      this.cachedObjects[data.id] = data;
    }
  }

  __getFromCache(id) {
    const cachedObject = this.cachedObjects[id] ?? null;

    if (cachedObject) {
      const timeDiff = new Date().getTime() - this.cachedObjectsTimestamps[id].timestamp;

      if (timeDiff > 60 * 1000) {
        return null;
      }

      return cachedObject;
    } else {
      return null;
    }
  }
}