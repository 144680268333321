import Loader from "./Loader";
import React, {useState} from "react";
import ContentCard from "./ContentCard";
import DeleteButton from "./Common/DeleteButton";
import OnDeleteConfirmPrompt from "./Common/OnDeleteConfirmPrompt";

const Styles = {
  actions: 'actions flex justify-between space-x-2 pt-5',
};

/**
 *
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export default function DeleteCard({ onDelete }) {
  const [promptIsOpen, setPromptIsOpen] = useState(false);

  const showPrompt = () => {
    setPromptIsOpen(true);
  }

  return (
    <ContentCard>
      <OnDeleteConfirmPrompt doAction={onDelete} isOpen={promptIsOpen} setIsOpen={setPromptIsOpen} />

      <div className="border-b border-red-500">
        <span className="text-xl font-medium text-red-500">Danger zone</span>
      </div>
      <div className={Styles.actions}>
        <div className="flex">
          <span>Note that deleting is permanent</span>
        </div>
        <DeleteButton type={"submit"} onClick={showPrompt} text={"Delete"}/>
      </div>
    </ContentCard>
  );
}
