import React from 'react';
import {classNames} from "../../lib/functions";
import {FiArrowLeft} from "react-icons/fi";

const Styles = {
  backButton: "cursor-pointer h-full mx-4 mt-1 hover:bg-gray-200 border shadow space-x-10 px-4 py-2 border-gray-200 text-gray-600 bg-white rounded-sm",
}

export default function BackButton({ text, onClick }) {
  return (
    <button className={classNames(Styles.backButton)} onClick={onClick}><FiArrowLeft/></button>
  );
}