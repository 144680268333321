import {createPortal} from 'react-dom';
import {useState} from "react";
import ModalCard from "./ModalCard";

/**
 * @param children
 * @param title
 * @param isOpen
 * @param toggleIsOpen
 * @returns {|null}
 * @constructor
 */
export default function Modal({ children, title, isOpen = false, toggleIsOpen = () => {} }) {
  const [ container ] = useState(() => {
    return document.getElementById('root');
  });

  if (!isOpen) return null

  return createPortal(
    <div className="modal top-0 left-0 fixed w-full h-full z-50 flex justify-center">
      <div onClick={toggleIsOpen} className="modal-background absolute top-0 left-0 w-full h-full bg-gray-800 opacity-50" />
      <div className="relative max-w-screen-xl top-20 inset-0">
        <div className="flex justify-center">
          <ModalCard title={title}>
            {children}
          </ModalCard>
        </div>
      </div>
    </div>,
    container)
}