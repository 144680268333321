export default function TextInput({fieldObject, fieldKey, register, registerOptions, className, data}) {
  const registerKey = fieldKey || fieldObject.field;

  return (
    <input type="text"
           placeholder={fieldObject.placeholder}
           disabled={ fieldObject.editable === false }
           className={className}
           {...register(registerKey, registerOptions(fieldObject, data)) }
    />
 );
}