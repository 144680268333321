import React, { useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {Controller} from "react-hook-form";

/**
 *
 * @param fieldObject
 * @param fieldKey
 * @param setValue
 * @param control
 * @param register
 * @param registerOptions
 * @param className
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
export default function RichTextEditorInput({fieldObject, fieldKey, setValue, control, register, registerOptions, className, data}) {
  const registerKey = fieldKey || fieldObject.field;

  return (
    <div>
      <Controller
        control={control}
        name={registerKey}
        render={({ field: {  value } }) => (
          <ReactQuill
            theme="snow"
            value={value}
            onChange={setValue}
          />
        )}
      />
    </div>
  );
}
