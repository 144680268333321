import {classNames} from "../../lib/functions";
import {Controller} from "react-hook-form";

/**
 *
 * @param fieldObject
 * @param control
 * @param setValue
 * @param fieldKey
 * @returns {JSX.Element}
 * @constructor
 */
export default function CheckboxInput({fieldObject, control, setValue, fieldKey }) {
  const registerKey = fieldKey || fieldObject.field;

  return (
    <Controller
      control={control}
      name={registerKey}
      render={({ field: {  value } }) => (
        <Checkbox
          isChecked={value}
          onClick={() => {
            setValue(!value);
          }}
        />
      )}
    />
  );
}

/**
 * @param isChecked
 * @param onClick
 * @returns {JSX.Element}
 * @constructor
 */
const Checkbox = ({ isChecked, onClick }) => {
  const classNameButton = 'transform relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500';
  const classNameCircle = 'transform translate-x-0 pointer-events-none h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200';

  const translateWhenChecked = classNames(isChecked ? 'translate-x-5': '', classNameCircle)

  return (
    <div className="relative">
      <button type="button" onClick={onClick} className={classNames(isChecked ? 'bg-green-500': 'bg-gray-200', classNameButton)} role="switch" aria-checked="false">
        <input type="checkbox"
               className="hidden"
        />
        <span id='little-circle' aria-hidden="true" className={translateWhenChecked}
        />
      </button>
    </div>
  );
}
