import {classNames} from "../../lib/functions";
import {RiDeleteBin6Line} from "react-icons/all";
import {FiArrowLeft, FiArrowRight} from "react-icons/fi";
import React, {useEffect, useState} from "react";
import {Styles as FormStyles} from "../Forms/StandardForm";

export const Styles = {
  uploadBlock: 'relative h-100 w-100 hover:bg-gray-100 cursor-pointer',
  uploadBlockNew: 'h-40 w-40 bg-gray-100 hover:bg-gray-200 cursor-pointer flex justify-center items-center border-dashed border-2 border-light-blue-500',
  uploadBlockExisting: 'bg-gray-200 hover:bg-gray-100 cursor-pointer',
  input: "h-full w-full bg-blue opacity-0 cursor-pointer",
  actions: "w-full flex justify-around",
  buttonAction: "flex flex-row items-center cursor-pointer shadow space-x-10 px-4 py-2 text-gray-600 rounded-sm focus:outline-none hover:ring-2 hover:bg-gray-200",
  arrowButton: "border border-gray-200 hover:bg-gray-200",
  hoverAction: ""
};

const ArrowButton = ({ action, left = false, right = false } ) => {
  return (
    <div onClick={action} className={Styles.buttonAction}>
      {left &&
      <FiArrowLeft/>
      }
      {right &&
      <FiArrowRight />
      }
  </div>
  );
}

/**
 *
 * @param tags
 * @param attachment
 * @param updateAttachment
 * @param deleteAttachment
 * @param listIndex
 * @param attachmentCount
 * @returns {JSX.Element}
 * @constructor
 */
export default function AttachmentBlock( { onClick, attachment, updateAttachment, deleteAttachment, listIndex, attachmentCount }) {
  const [attach, setAttach] = useState(attachment);
  const [tags, setTags] = useState(attachment.tags);

  useEffect(() => {
    setTags(attachment.tags);
  }, [attachment.tags]);

  const moveLeft = (attachment) => {
    let orderColumn = attachment.order_column - 2;

    orderColumn = orderColumn < 0 ? 0: orderColumn;

    setAttach({
      ...attach,
      order_column: orderColumn
    })

    updateAttachment(attachment, attach);
  };

  const moveRight = (attachment) => {
    const orderColumn = attachment.order_column + 2;

    setAttach({
      ...attach,
      order_column: orderColumn
    })

    updateAttachment(attachment, attach);
  };

  const handleTagsUpdate = (e) => {
    const value = e.target.value;

    setTags(value);
    const newAttachment = {
      ...attach,
      tags: value,
    }

    setAttach(newAttachment);
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      updateAttachment(attachment, attach);
    }
  }

  return (
    <>
      <div className="w-full flex justify-end py-2">
        <input type="text"
               className={FormStyles.input}
               disabled={true}
               value={attachment.file_name}
        />
        <div onClick={(e) => { e.preventDefault(); deleteAttachment(attachment)}} className={Styles.buttonAction}>
          <RiDeleteBin6Line />
        </div>
      </div>
      <div className="flex justify-center border">
        <img
          onClick={onClick}
          width={attachment.width}
          src={attachment.medium}
          height={attachment.height}
          className={classNames(Styles.uploadBlock, Styles.uploadBlockExisting)}
          alt="123"
        />
      </div>
      <div className={Styles.actions}>
        <ArrowButton left={true} action={() => moveLeft(attachment)} />
        <input type="text"
               key={listIndex + 'textInput'}
               style={{textTransform: "uppercase"}}
               onChange={handleTagsUpdate}
               onKeyUp={handleEnter}
               onBlur={() => updateAttachment(attachment, attach)}
               placeholder='tag1,tag2,tag3,...'
               className={FormStyles.input}
               value={tags}
        />
        {listIndex + 1 < attachmentCount &&
        <ArrowButton right={true} action={() => moveRight(attachment)} />
        }
        {listIndex + 1 === attachmentCount &&
        <div className="invisible">
          <FiArrowRight/>
        </div>
        }
      </div>
    </>
  );
}