import Loader from "../Loader";
import AttachmentUploadBlock from "../AttachmentUploadBlock";
import AttachmentBlock from "../Attachments/AttachmentBlock";
import {useCallback, useState} from "react";
import {registerOptions} from "./StandardForm";
import api from "../../lib/api";

/**
 * @param fieldObject
 * @param data
 * @param ownerModel
 * @param model
 * @param refresh
 * @returns {JSX.Element}
 * @constructor
 */
export default function AttachmentUploadsToField({ fieldObject, ownerModel, model, refresh }) {
  const field = registerOptions(fieldObject, model);

  const attachments = field?.value || [];

  const [isLoading, setIsLoading] = useState(false);

  const [uploadedFileCount, setUploadedFileCount] = useState(0);
  const [totalUploadingFilesCount, setTotalUploadingFilesCount] = useState(0);

  const uploadFilesCallback = (async function (e) {
    setIsLoading(true);

    const files = Array.from(e.target.files);
    setTotalUploadingFilesCount(files.length);

    const file = files[0];
    let uploadedFileCount = 0;

    const formData = new FormData();
    formData.append('file', file);

    await api.attachments.addToField(ownerModel.type, model.id, fieldObject.field, formData);
    setUploadedFileCount(uploadedFileCount + 1);
    await refresh();

    setIsLoading(false);

    setUploadedFileCount(0);
    setTotalUploadingFilesCount(0);
  });

  const deleteAttachmentCb = useCallback(async function (attachment) {
    setIsLoading(true);
    await api.attachments.delete(attachment.id);
    await refresh();
    setIsLoading(false);
  }, [refresh]);

  const updateAttachmentCb = useCallback(async function(attachment, data) {
    setIsLoading(true);
    await api.attachments.edit(attachment.id, data);
    await refresh();
    setIsLoading(false);
  }, [refresh]);

  return (
    <>
      {isLoading &&
      <div className="relative">
        <div className="absolute right-0 px-5 py-5">
          <Loader/>
        </div>
      </div>
      }
      <div>
        <AttachmentUploadBlock
          totalFilesUploadingCount={totalUploadingFilesCount}
          uploadedFileCount={uploadedFileCount}
          setAttachment={uploadFilesCallback}
          deleteAttachment={deleteAttachmentCb}
        />
        <div className="flex flex-wrap gap-2">
          {attachments.map((attachment, index) => (
            <div key={index} className="w-1/3">
              <>
                <AttachmentBlock
                  key={index + 'attachment'}
                  tags={attachment.tags}
                  attachment={attachment}
                  attachmentCount={attachments.length}
                  deleteAttachment={deleteAttachmentCb}
                  listIndex={index}
                  updateAttachment={updateAttachmentCb}
                />
              </>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
