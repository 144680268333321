import Header from "./Header";
import 'react-toastify/dist/ReactToastify.css';
import Container from "./Container";
import NavSidebar from "./NavSidebar";
import {ToastContainer} from "react-toastify";


/**
 *
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export default function Layout({ children }) {
  return (
    <Container>
      <ToastContainer />

      <Header />

      <div className="layout w-full min-h-screen flex">
        <NavSidebar />

        {children}
      </div>
    </Container>
  );
}
