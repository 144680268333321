import {FiPlus} from "react-icons/all";
import {useEffect, useState} from "react";
import {getPaginationInfoFromData, toggleState} from "../../lib/functions";
import ProductOption from "../../models/ProductOption";
import SideModal from "../SideModal";
import SaveButton from "../Common/SaveButton";
import ActionButton from "../Common/ActionButton";
import OptionValuesList from "../Common/OptionValuesList";
import Product from "../../models/Product";
import Modal from "../Modal";
import CreateProductOptionForm from "./CreateProductOptionForm";


/**
 * @returns {JSX.Element}
 * @constructor
 */
export default function ChooseProductOptionsContainer({ productId, selectedOptionValues, setSelectedOptionValues, afterVariantCreated, refresh }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenCreateVariantsSideModal, setIsOpenCreateVariantsSideModal] = useState(false);
  const [isOpenCreateNewOptionModal, setIsOpenCreateOptionModal] = useState(false);

  const [productOptionsAvailable, setProductOptionsAvailable] = useState([]);
  const [ paginationInfo, setPaginationInfo ] = useState(null);

  const loadData = () => {
    const params = {
      page: 1,
    }

    return ProductOption.store.get(params).then((response) => {
      const data = response.data;
      const paginationInfo = getPaginationInfoFromData(data);

      setProductOptionsAvailable(data.data);
      setPaginationInfo(paginationInfo);
    })
  }

  useEffect(function() {
    return loadData();
  }, []);

  // const toggleModalCreateNewOptionModal = () => {
  //   setIsOpenCreateOptionModal(!isOpenCreateNewOptionModal);
  // }
  //
  // const toggleModalCreateVariants = () => {
  //   setIsOpenCreateVariantsSideModal(!isOpenCreateVariantsSideModal);
  // }

  const onSubmit = () => {
    setIsLoading(true);

    const selectedOptionValueIds = Object.values(selectedOptionValues);

    return Product.store.createVariants(productId, {option_value_ids: selectedOptionValueIds}).then((response) => {
      setSelectedOptionValues({});
      setIsOpenCreateVariantsSideModal(false);
      afterVariantCreated(response.data.variants[response.data.variants.length - 1]);
    }).finally(() => {
      setIsLoading(false);
    })
  }

  return (
    <div>
      <ActionButton icon={FiPlus} text={"Add Variant"} onClick={ toggleState(setIsOpenCreateVariantsSideModal, isOpenCreateVariantsSideModal) } />
      <SideModal title={"Select an Option"} isOpen={isOpenCreateVariantsSideModal} toggleIsOpen={toggleState(setIsOpenCreateVariantsSideModal, isOpenCreateVariantsSideModal)} >
        <ActionButton icon={FiPlus} text={"Add new Option"} onClick={ toggleState(setIsOpenCreateOptionModal, isOpenCreateNewOptionModal)} />
        <OptionValuesList
          options={productOptionsAvailable}
          selectedOptionValues={selectedOptionValues}
          handleDeactivate={loadData}
          setOptionValues={setSelectedOptionValues}
          refresh={refresh}
        />

        <div className='absolute bottom-10 right-5'>
          <SaveButton onClick={() => {}} text="Use selected options" onCLick={onSubmit} />
        </div>
      </SideModal>

      <Modal isOpen={isOpenCreateNewOptionModal}>
        <CreateProductOptionForm handleDeactivate={() => {loadData().then(toggleState(setIsOpenCreateOptionModal, isOpenCreateNewOptionModal))}} />
      </Modal>
    </div>
  );
}