export default function TextAreaInput({fieldObject, register, registerOptions, className, data}) {
 return (
   <textarea
          placeholder={fieldObject.placeholder}
          disabled={ fieldObject.editable === false }
          className={className}
          cols="40"
          rows="5"
          {...register(fieldObject.field, registerOptions(fieldObject, data)) }
   />
 );
}