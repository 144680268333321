import UrlFormItem from "./UrlFormItem";
import {toFormFieldsGrouped} from "../../lib/functions";
import Url from "../../models/Url";
import {useFieldArray} from "react-hook-form";
import React, {useEffect} from "react";
import ActionButton from "../Common/ActionButton";
import {FiPlus} from "react-icons/all";

export default function AddUrlsForm({ model, formRef, ownerModel }) {
  const urls = model.urls;

  const {
    control,
    register,
    getValues,
    registerOptions,
    setValue } = formRef.current;

  const formFields = toFormFieldsGrouped(Url);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "urls",
  });

  useEffect(() => {
    register('urls');
  }, []);

  return (
    <div className="overflow-hidden shadow sm:rounded-md">
      <div className="flex-col px-4 py-5 space-y-4 bg-white sm:p-6">
        <div className="space-y-4">
          <div>
            <div className="flex items-center space-x-4 text-sm font-medium text-gray-700">
              <div className="w-64">Language</div>
              <div className="w-full">Slug</div>
              <div className="w-32">Default</div>
            </div>
          </div>
          {fields.map((field, index) => (
            <div key={'input' + index} className="flex items-center space-x-4">
              <UrlFormItem
                control={control}
                data={model}
                formFields={formFields}
                getValues={getValues}
                index={index}
                register={register}
                registerOptions={registerOptions}
                remove={remove}
                setValue={setValue}
                urls={urls}
              />
            </div>
          ))}

          <ActionButton type="button" icon={FiPlus} text={"Add URL"} onClick={ () => append({ slug: null })  } />
        </div>
      </div>
    </div>
  );
}