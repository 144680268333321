import Button, {ButtonStyleType} from "../Common/Button";
import {DevTool} from "@hookform/devtools";
import React, {forwardRef, useEffect, useImperativeHandle} from "react";
import {useForm, useFormState} from "react-hook-form";
import IF from "../Common/IF";
import {isEmpty} from "lodash";
import FormField from "./FormField";

export const Styles = {
  actions: 'actions flex justify-end space-x-2 pt-5',
  save: "bg-green-500 hover:bg-green-500 focus:ring-green-400",
  cancel: "flex flex-row items-center cursor-pointer border shadow space-x-10 px-4 py-2 border-gray-200 text-gray-600 rounded-sm",
  // input: "block appearance-none border rounded w-full py-2 px-3 h-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
  input: "form-select border block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:outline-none sm:text-sm",
  urlInput: "form-select border block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:outline-none sm:text-sm",
  email: "block shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
  inActive: "bg:gray-200",
  inputRow: "flex flex-col mt-2",
  halfWidth: "w-49 grow-1",
  errors: "text-sm text-red-500 pt-1",
};

/**
 * @param fieldObject
 * @param data
 * @returns {{required: boolean}}
 */
export const registerOptions = (fieldObject, data = null) => {
  const resultOptions = {
    required: fieldObject.required ?? false,
  }

  if (data && (data[fieldObject.field] ?? null)) {
    resultOptions.value = data[fieldObject.field];
  } else if (fieldObject.default ?? null) {
    resultOptions.value = fieldObject.default;
  }

  return resultOptions;
};

const StandardForm = forwardRef(({ formFields, onSubmit, hideSaveButtons = false, handleDeactivate = null, model: data = null, children = null }, ref ) => {

  const { register, watch, handleSubmit, control, setValue, getValues, formState: { errors } } = useForm();

  const { dirtyFields } = useFormState({
    control
  });

  useEffect(() => {
    formFields.forEach((fieldObject) => {
      if (data && data.hasOwnProperty(fieldObject.field)) {
        register(fieldObject.field);
        setValue(fieldObject.field, registerOptions(fieldObject, data).value);
      }
    });
  }, [data])

  const submitTrigger = handleSubmit((data) => {
    onSubmit(data);
  });

  useImperativeHandle(ref, () => ({
    setValue: setValue,
    getValues: getValues,
    register: register,
    control: control,
    registerOptions: registerOptions,
    submit() {
      return submitTrigger();
    },
  }))

  if (!isEmpty(errors)) {
    console.log("Form errors:", errors);
  }

  return (
    <form className="w-full flex flex-col justify-between" onSubmit={submitTrigger}>
      <DevTool control={control} />

      <div className="flex flex-wrap gap-2">
          {formFields.map((fieldObject, index) => (
            <FormField
              key={"formField" + index}
              watch={watch}
              fieldObject={fieldObject}
              index={index}
              getValues={getValues}
              setValue={setValue}
              errors={errors}
              registerOptions={registerOptions}
              register={register}
              control={control}
              data={data}
            />
          ))}
          { children }
        </div>
        <IF condition={!hideSaveButtons}>
          <div className={Styles.actions}>
            <IF condition={!!handleDeactivate}>
              <Button styleType='natural' onClick={handleDeactivate}>Cancel</Button>
            </IF>
            <Button
              type="submit"
              disabled={false}
              className={Styles.save}
              text={'Save'}
            />
          </div>
        </IF>
    </form>
  );
});

export default StandardForm;