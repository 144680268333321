import axios from "../axios";
import BaseEndpoints from "./BaseEndpoints";
const baseUrl = process.env.REACT_APP_API_URL;

export const resource = 'attachments';


class AttachmentsEndpoints extends BaseEndpoints {
  /**
   * @param data
   * @param owner
   * @param ownerId
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  addTo(owner, ownerId, data) {
    return axios.post(`${baseUrl}/api/1/${owner}/${ownerId}/${resource}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
  }
  addToField(owner, ownerId, field, data) {
    return axios.post(`${baseUrl}/api/1/${owner}/${ownerId}/${resource}/${field}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
  }
}

export default new AttachmentsEndpoints(resource);