import Modal from "../Modal";
import React from "react";
import AttachmentEditor from "./AttachmentEditor";

/**
 * @param attachment
 * @param updateAttachment
 * @param isOpen
 * @param toggleIsOpen
 * @returns {JSX.Element}
 * @constructor
 */
export default function AttachmentEditorModal({ attachment, updateAttachment, isOpen, toggleIsOpen }) {
  return (
  <Modal title={"Edit this Image"} isOpen={isOpen} toggleIsOpen={toggleIsOpen} >
      <AttachmentEditor
        handleDeactivate={toggleIsOpen}
        updateAttachment={updateAttachment}
        attachment={attachment}
      />
  </Modal>
);
}