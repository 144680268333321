import Button from "./Button";

const Styles = {
  save: "bg-green-500 hover:bg-green-500 focus:ring-green-400",
};

export default function SaveButton({ text, onCLick = () => {} }) {
  return (
    <Button type="submit" className={Styles.save} onClick={onCLick} text={text} />
  );
}