import React from "react";
import api from '../../lib/api';
import {useHistory, useLocation} from "react-router-dom";
import StandardForm from "../../components/Forms/StandardForm";
import Layout from "../../components/Layout";
import Content from "../../components/Content";
import ContentCard from "../../components/ContentCard";
import {toFormFields} from "../../lib/functions";
import DeleteCard from "../../components/DeleteCard";
import ProductOption from "../../models/ProductOption";
import BackButton from "../../components/Common/BackButton";

/**
 * @returns {JSX.Element}
 * @constructor
 */
function EditProductOptionPage() {
  const history = useHistory();
  const location = useLocation();

  const { model } = location.state;

  const onSubmit = async (data) => {
    return ProductOption.store.edit(model.id, data).then(() => {
      handleDeactivate();
    })
  }

  const onDelete = async () => {
    return ProductOption.store.delete(model.id).then(() => {
      handleDeactivate();
    })
  }

  function handleDeactivate() {
    history.goBack();
  }

  return (
    <Layout>
      <Content className="add">
        <div>
          <div className="content-header w-full flex justify-between mb-5">
            <div className={"flex"}>
              <BackButton onClick={handleDeactivate}/>
              <h1 className="block">Edit<span className="text-base"> (id: {model.id})</span></h1>
            </div>
            {/*<div className="actions float-right"></div>*/}
          </div>
          <ContentCard>
            <StandardForm formFields={toFormFields(ProductOption)}
                          onSubmit={onSubmit}
                          handleDeactivate={handleDeactivate}
                          model={model}
            />
          </ContentCard>
          <DeleteCard onDelete={onDelete} />
        </div>
      </Content>
    </Layout>
  );
}

export default EditProductOptionPage;
