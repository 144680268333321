import React, { useEffect, useState } from "react";

import {jwtDecode} from "./lib/jwt-helpers";
import {BrowserRouter, Router, Route, Switch } from "react-router-dom";
import {UserContext} from "./contexts/UserContext";
import AuthRoute from "./routes/AuthRoute";
import AdminPage from "./pages/Dashboard/AdminPage";
import SettingsPage from "./pages/Settings/SettingsPage";
import LoginPage from "./pages/Login/LoginPage";
import SignupPage from "./pages/Signup/SignupPage";

import ListProductsPage from "./pages/Products/ListProductsPage";
import EditProductsPage from "./pages/Products/EditProductsPage";
import AddProductsPage from "./pages/Products/AddProductsPage";

import ListProductTypePage from "./pages/ProductTypes/ListProductTypePage";
import AddProductTypePage from "./pages/ProductTypes/AddProductTypePage";
import EditProductTypePage from "./pages/ProductTypes/EditProductTypePage";
import ListCustomerPage from "./pages/Customers/ListCustomerPage";
import AddCustomerPage from "./pages/Customers/AddCustomerPage";
import EditCustomerPage from "./pages/Customers/EditCustomerPage";
import SuperAdminAuthRoute from "./routes/SuperAdminAuthRoute";
import ListBrandPage from "./pages/Brands/ListBrandPage";
import AddBrandPage from "./pages/Brands/AddBrandPage";
import EditBrandPage from "./pages/Brands/EditBrandPage";
import NotFoundPage from "./pages/Common/not-found";
import {AppContext} from "./contexts/AppContext";
import EditProductVariantPage from "./pages/ProductVariants/EditProductVariantPage";
import ListProductVariantPage from "./pages/ProductVariants/ListProductVariantPage";
import { createBrowserHistory } from "history";
import ListProductOptionPage from "./pages/ProductOptions/ListProductOptionPage";
import AddProductOptionPage from "./pages/ProductOptions/AddProductOptionPage";
import EditProductOptionPage from "./pages/ProductOptions/EditProductOptionPage";
import ListCollections from "./pages/Collections/ListCollections";
import AddCollections from "./pages/Collections/AddCollections";
import EditCollections from "./pages/Collections/EditCollections";

export const history = createBrowserHistory();

function Routes() {
  const [ userContext, setUserContext] = useState(null);
  const [ appContext, setAppContext] = useState(null);
  const [ loaded, setLoaded ]= useState(false)

  useEffect(() => {
    const token = localStorage.getItem('jwt-token');

    if (token) {
      const user = jwtDecode(token);

      setUserContext({
        user: {
          name: user.name,
          email: user.email,
          roles: user.roles,
        }
      })
    } else {
      setUserContext(null);
    }

    setLoaded(true);
  }, []);

  return (
    <Router history={history}>
      <div>
        {!loaded && <div>Loading ... </div> }
        { loaded && [
          <UserContext.Provider value={{userContext, setUserContext}}>
            <AppContext.Provider value={{appContext, setAppContext}}>
              <Switch>
                <AuthRoute key='home' path="/" exact component={AdminPage} />
                <AuthRoute key='dashboard' path="/dashboard" exact component={AdminPage} />

                <AuthRoute exact key="products" path="/products" component={ListProductsPage} />
                <AuthRoute key='products/add' path='/products/add' component={AddProductsPage}/>
                <AuthRoute key='products/edit' path='/products/edit/:id' component={EditProductsPage} />

                {/*<AuthRoute exact key="influencers" path="/influencers" component={ListInfluencersPage} />*/}
                {/*<AuthRoute key='influencers/add' path='/influencers/add' component={AddInfluencersPage}/>*/}
                {/*<AuthRoute key='influencers/edit' path='/influencers/edit/:influencerId' component={EditInfluencersPage} />*/}

                {/*<AuthRoute exact key="categories" path="/categories" component={ListCategoriesPage} />*/}
                {/*<AuthRoute key='categories/add' path='/categories/add' component={AddCategoriesPage}/>*/}
                {/*<AuthRoute key='categories/edit' path='/categories/edit/:categoryId' component={EditCategoriesPage} />*/}

                <AuthRoute exact key="product-types" path="/product-types" component={ListProductTypePage} />
                <AuthRoute key='product-types/add' path='/product-types/add' component={AddProductTypePage}/>
                <AuthRoute key='product-types/edit' path='/product-types/edit/:id' component={EditProductTypePage} />

                <AuthRoute exact key="product-variants" path="/products/:productId/product-variants" component={ListProductVariantPage} />
                <AuthRoute key='product-variants/edit' path='/products/:productId/product-variants/:id' component={EditProductVariantPage} />

                <SuperAdminAuthRoute exact key="customers" path="/customers" component={ListCustomerPage} />
                <SuperAdminAuthRoute key='customers/add' path='/customers/add' component={AddCustomerPage}/>
                <SuperAdminAuthRoute key='customers/edit' path='/customers/edit/:id' component={EditCustomerPage} />

                <SuperAdminAuthRoute exact key="brands" path="/brands" component={ListBrandPage} />
                <SuperAdminAuthRoute key='brands/add' path='/brands/add' component={AddBrandPage}/>
                <SuperAdminAuthRoute key='brands/edit' path='/brands/edit/:id' component={EditBrandPage} />

                <AuthRoute exact key="product-options" path="/product-options" component={ListProductOptionPage} />
                <AuthRoute key='product-options/add' path='/product-options/add' component={AddProductOptionPage}/>
                <AuthRoute key='product-options/edit' path='/product-options/edit/:id' component={EditProductOptionPage} />

                <AuthRoute exact key="collections" path="/collections" component={ListCollections} />
                <AuthRoute key='collections/add' path='/collections/add' component={AddCollections}/>
                <AuthRoute key='collections/edit' path='/collections/edit/:id' component={EditCollections} />

                <AuthRoute key="settings" path="/settings" component={SettingsPage} />

                <Route key="login" path="/login" component={LoginPage}/>
                <Route key="not-found" path="/not-found" component={NotFoundPage}/>
                <Route key="signup" path="/signup" component={SignupPage} />

              </Switch>
            </AppContext.Provider>
          </UserContext.Provider>
        ]}
      </div>
    </Router>
  );
}

export default Routes;