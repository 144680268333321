import Table from "./Table";
import { useCallback, useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {isEmpty} from "lodash";
import NumberFormat from "react-number-format";

/**
 *
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProductVariantsTable({product}) {
  const variants = product.variants;
  const { url } = useRouteMatch();
  const history = useHistory();

  const onClickRow = useCallback((model) => {
    history.push(`/products/${product.id}/product-variants/${model.id}`);
  }, [history, url]);

  const columns = useMemo(
    () => [
      {
        Header: 'Variant Options',
        accessor: (variant) => {
          if (isEmpty(variant.values)) {
            return 'Default variant';
          }

          return variant.values.map((o) => `${o.variant_name}`).join(' / ')
        }
      },
      {
        Header: 'SKU',
        accessor: 'sku',
      },
      {
        accessor: (value) => {
          return value;
        },
        Header: 'Price',
        Cell: ({value}) => {
          return <NumberFormat value={value.price}
                               displayType={'text'}
                               thousandSeparator='.'
                               decimalSeparator=','
                               prefix={'USD '} />
        }
      },
      {
        Header: 'action',
        accessor: (model) => {
          return model;
        },
        Cell: (model) => {
          return <span className="text-blue-600 no-underline hover:underline" onClick={() => onClickRow(model)}>Edit</span>
        }
      },
    ],[]
  )

  return (
    <Table data={variants} isLoading={variants === null} onClickRow={onClickRow} columns={columns} />
  );
}