import {groupBy} from "lodash";

export const toInputTypeMap = {
  attachedValues: 'attached-values',
  belongsTo: 'select-list-belongs-to',
  belongsToMany: 'select-list-belongs-to-many',
  boolean: 'checkbox',
  email: 'email',
  fileUpload: 'fileUpload',
  hasMany: 'hasMany',
  number: 'number',
  price: 'price',
  richText: 'richText',
  select: 'select',
  string: 'text',
  text: 'textarea',
  url: 'url',
};

/**
 * @param model
 * @param topic
 * @returns {{field: string, length, label: *, type: *, required}[]}
 */
export const toFormFields = (model, topic = null) => {
  return Object.keys(model.fields).map(function(key) {
    const fieldMetaObject = model.fields[key];

    return {
      column: fieldMetaObject.column ?? 'left',
      default: fieldMetaObject.default ?? null,
      defaultOptionIndex: fieldMetaObject.defaultOptionIndex ?? null,
      editable: fieldMetaObject.editable ?? true,
      field: key,
      findOptions: belongsToFilter(model, fieldMetaObject.contentType, key),
      contentType: fieldMetaObject.contentType,
      findOwner: findOwner(model, fieldMetaObject.contentType),
      findOwners: findOwners(model, fieldMetaObject.contentType),
      findSelectedOption: fieldMetaObject.options ? findSelectedOption(fieldMetaObject): () => {},
      hidden: (fieldMetaObject.hidden ?? false) || (topic && fieldMetaObject.topic !== topic),
      label: fieldMetaObject.label,
      length: fieldMetaObject.length ?? null,
      options: fieldMetaObject.options ?? [],
      ownerModel: fieldMetaObject.contentType,
      required: fieldMetaObject.required ?? false,
      style: fieldMetaObject.style ?? null,
      topic: fieldMetaObject.topic ?? null,
      type: toInputTypeMap[fieldMetaObject.type],
      placeholder: fieldMetaObject.placeholder ?? null,
    };
  });
};

/**
 * @param model
 * @param groupKey
 * @returns {Dictionary<[unknown, ...unknown[]]>}
 */
export const toFormFieldsGrouped = (model, groupKey = 'field') => {
  const formFields = toFormFields(model);

  return groupBy(formFields, groupKey);
}

/**
 * @param fieldMetaObject
 * @returns {function(*=): *}
 */
export const findSelectedOption = (fieldMetaObject) => {
  return (value) => {
    return fieldMetaObject.options.find((obj) => obj.value === value);
  }
}

/**
 *
 * @param model
 * @param ownerModel
 * @returns {function(*, *): *}
 */
export const belongsToFilter = (model, ownerModel) => {
  return (searchText = null) => {
    let options = {};

    if (searchText) {
      options = {params: {}};

      // search on name by default
      const searchField = ownerModel.searchField ?? 'name';
      options.params[searchField] = searchText;
    }

    return ownerModel.store.findAll(options).then((response) => {
      return response.data;
    });
  }
}

/**
 *
 * @param model
 * @param ownerModel
 * @returns {function(*=): Promise<AxiosResponse<*>>|*}
 */
export const findOwner = (model, ownerModel) => {
  return (id) => {
    return ownerModel.store.findById(id, true);
  }
}

/**
 *
 * @param model
 * @param ownerModel
 * @returns {function(*=): Promise<AxiosResponse<*>>|*}
 */
export const findOwners = (model, ownerModel) => {
  return (ids) => {
    return ownerModel.store.findByIds(ids, true);
  }
}

/**
 * @param data { Object }
 */
export function getPaginationInfoFromData(data) {
  const toUrl = (url) => {
    if (!url) {
      return '';
    }

    const urlObj = new URL(url);

    return `${urlObj.search}`;
  }

  return {
    current_page: data.current_page,
    first_page: data.first_page,
    first_page_url: data.first_page_url,
    from: data.from,
    last_page: data.last_page,
    last_page_url: data.last_page_url,
    links: data.links,
    next_page_url: toUrl(data.next_page_url),
    path: data.path,
    per_page: data.per_page,
    prev_page_url: toUrl(data.prev_page_url),
    to: data.to,
    total: data.total,
  }
}

/**
 *
 * @param classes
 * @returns {string}
 */
export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/**
 *
 * @param setter
 * @param value
 * @returns {(function(): void)|*}
 */
export function toggleState(setter, value) {
  return () => {
    setter(!value);
  }
}

