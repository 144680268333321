import {useContext} from "react";
import {UserContext} from "../contexts/UserContext";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const Header = function() {
  const { userContext, setUserContext } = useContext(UserContext);

  const logout = () => {
    setUserContext(null);

    localStorage.clear();
  };

  return (
    <div className="header shadow-lg">
      <div className="flex flex-row justify-between w-full p-2 items-baseline">
        <div className="left logo">
          <h4>Lento</h4>
        </div>
        <div className="right flex flex-row space-x-4 items-baseline">
          <h4>{userContext.user.name}</h4>
          <button onClick={logout} className="py-2 px-4 bg-gray-400 text-white font-semibold rounded-lg shadow-md hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75">Logout</button>
        </div>
      </div>
    </div>
  );
};

export default Header;