import BaseEndpoints from "../lib/endpoints/BaseEndpoints";

const User = {
  store: new BaseEndpoints('users'),

  type: 'user',
  modelName: 'User',
  searchField: 'email',
  fields: {
    firstname: {
      type: 'string',
      length: 30,
      required: true,
      label: 'First name',
    },
    lastname: {
      type: 'string',
      length: 512,
      required: true,
      editable: true,
      label: 'lastname',
      style: 'w-full',
    },
    email: {
      type: 'string',
      length: 512,
      required: true,
      editable: true,
      label: 'email',
      style: 'w-full',
    },
  },
  display(data) {
    return `${data.name}`;
  },
};

export default User;
