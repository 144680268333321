import React from 'react';
import {classNames} from "../../lib/functions";

const Styles = {
  button: "cursor-pointer w-full h-full mx-4 mt-1 border shadow space-x-10 px-4 py-2 border-gray-200 rounded-sm",
  isSelected: 'bg-green-400 text-white',
  notSelected: 'hover:bg-gray-200 text-gray-600 bg-white',
}

export default function OptionElement({ option, onClick, isSelected, selectedOptions }) {
  // const isSelected = selectedOptions[option.id] ?? false;
  const classNamesList = classNames(isSelected ? Styles.isSelected: Styles.notSelected, Styles.button);

  return (
    <button
      className={classNamesList} onClick={() => onClick(option)}>
      {option.name}
    </button>
  );
}