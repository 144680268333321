import {useCallback, useState} from "react";
import AttachmentUploadBlock from "../AttachmentUploadBlock";
import api from "../../lib/api";
import Loader from "../Loader";
import AttachmentBlock from "./AttachmentBlock";
import AttachmentEditorModal from "./AttachmentEditorModal";
import {toastWithMessage} from "../../lib/toast-helpers";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function AttachmentUploads({ ownerModel, model, refresh }) {
  const { attachments = [] } = model;
  const [isLoading, setIsLoading] = useState(false);

  const [uploadedFileCount, setUploadedFileCount] = useState(0);
  const [totalUploadingFilesCount, setTotalUploadingFilesCount] = useState(0);
  const [currentAttachment, setCurrentAttachment] = useState(null);

  const uploadFilesCallback = (async function(e) {
    setIsLoading(true);

    const files = Array.from(e.target.files);
    setTotalUploadingFilesCount(files.length);

    let uploadedFileCount = 0;

    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);

      await api.attachments.addTo(ownerModel.type, model.id, formData);
      setUploadedFileCount(uploadedFileCount + 1);

      await refresh();
    }

    setIsLoading(false);

    setUploadedFileCount(0);
    setTotalUploadingFilesCount(0);

  });

  const deleteAttachmentCb = useCallback(async function(attachment) {
    setIsLoading(true);
    await api.attachments.delete(attachment.id);
    await refresh();
    setIsLoading(false);
  }, [refresh]);

  const updateAttachmentCb = useCallback(async function(attachment, data) {
    setIsLoading(true);
    const updatedAttachment = await api.attachments.edit(attachment.id, data);

    if (currentAttachment) {
      setCurrentAttachment(updatedAttachment);
    } else {
      await refresh();
    }

    setIsLoading(false);
  }, [refresh]);

  return (
    <>
      {isLoading &&
      <div className="relative">
        <div className="absolute right-0 px-5 py-5">
          <Loader/>
        </div>
      </div>
      }
      <div>
        {currentAttachment &&
        <AttachmentEditorModal
          attachment={currentAttachment}
          isOpen={!!currentAttachment}
          updateAttachment={updateAttachmentCb}
          toggleIsOpen={() => {
            setCurrentAttachment(null)
          }}
        />
        }
        <AttachmentUploadBlock
          totalFilesUploadingCount={totalUploadingFilesCount}
          uploadedFileCount={uploadedFileCount}
          setAttachment={uploadFilesCallback}
          deleteAttachment={deleteAttachmentCb}
        />
        <div className="flex flex-wrap gap-2">
          {attachments.map((attachment, index) => (
            <div key={index} className="w-1/3">
              <div>
                <AttachmentBlock
                  key={index + 'attachment'}
                  onClick={() => setCurrentAttachment(attachment)}
                  tags={attachment.tags}
                  attachment={attachment}
                  attachmentCount={attachments.length}
                  deleteAttachment={deleteAttachmentCb}
                  listIndex={index}
                  updateAttachment={updateAttachmentCb}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );

}