import React, { useEffect, useState } from "react";
import { useTable } from 'react-table'
import { FiArrowRight, FiArrowLeft } from  "react-icons/fi";
import {Link} from "react-router-dom";
import {classNames} from "../lib/functions";
import Loader from "./Loader";

const Styles = {
  footerAction: "flex flex-row items-center border shadow space-x-10 px-4 py-2 border-gray-200 text-gray-600 rounded-sm",
  footerActionDisabled: 'bg-gray cursor-not-allowed',
  footerActionEnabled: 'bg-white hover:bg-green-500 hover:text-white',
  header: "bg-gray-50",
  table: "min-w-full divide-y divide-gray-200 shadow-sm border-gray-200 rounded-sm",
  tbody: "bg-white divide-y divide-gray-200",
  td: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-cool-gray-900",
  th: "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider",
  thContent: "text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider",
  tr: "bg-white even:bg-gray-50 cursor-pointer",
}

export default function Table({ columns, data, paginationInfo, onClickRow = () => {}, isLoading = false}) {
  // Use the state and functions returned from useTable to build your UI
  const tData = data || [];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: tData,
  })

  // Render the UI for your table
  return (
    <>
    <table className={Styles.table} {...getTableProps()}>
      <thead className={Styles.header}>
      {headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => (
            <th className={Styles.th} {...column.getHeaderProps()}><span className={Styles.thContent}>{column.render('Header')}</span></th>
          ))}
        </tr>
      ))}
      </thead>
      <tbody className={Styles.tbody} {...getTableBodyProps()}>
      { rows.length > 0 && rows.map((row, i) => {
        prepareRow(row)
        return (
          <tr onClick={() => onClickRow(row.original)} className={Styles.tr} {...row.getRowProps()}>
            {
              row.cells.map(cell => {
              return <td className={Styles.td} {...cell.getCellProps()}>{cell.render('Cell')}</td>
            })
            }
          </tr>
        )
      })}
      </tbody>
    </table>
      { isLoading &&
      <div className="flex justify-center py-10 bg-white">
        <Loader />
      </div>
      }
      { paginationInfo &&
      <div className='table-footer flex flex-row justify-end space-x-5 mt-5'>
        <Link className={classNames(
          paginationInfo.prev_page_url ? Styles.footerActionEnabled: Styles.footerActionDisabled, Styles.footerAction)}
              to={ paginationInfo.prev_page_url || "#" }><FiArrowLeft/></Link>
        <Link className={classNames(paginationInfo.next_page_url ? Styles.footerActionEnabled: Styles.footerActionDisabled, Styles.footerAction)}
              to={ paginationInfo.next_page_url || "#" }><FiArrowRight/></Link>
      </div>
      }
    </>
  )
}