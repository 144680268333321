import productVariantEndpoints from "../lib/endpoints/ProductVariantEndpoints";
import Product from "./Product";
import TaxClass from "./TaxClass";

const ProductVariant = {
  store: productVariantEndpoints,

  type: 'product_variants',
  modelName: 'ProductVariant',
  fields: {
    product_id: {
      // type: 'attached-values',
      length: 30,
      required: true,
      hidden: true,
      contentType: Product,
      editable: false,
      label: 'Product'
    },
    shippable: {
      type: 'boolean',
      required: false,
      label: 'shippable',
      default: 1,
      topic: 'shippable',
    },
    tax_class_id: {
      type: 'belongsTo',
      required: true,
      contentType: TaxClass,
      label: 'TaxClass',
      default: 1,
      topic: 'pricing',
    },
    uuid: {
      type: 'string',
      editable: false,
      hidden: false,
      label: 'Unique Identifier (UUID)',
      length: 36,
      topic: 'product_identifiers'
    },
    unit_quantity: {
      type: 'number',
      required: false,
      editable: true,
      label: 'Unit quantity',
      default: 1,
      topic: 'pricing',
    },
    currency_id: {
      type: 'select',
      required: true,
      default: 1,
      topic: 'pricing',
      label: 'Currency',
      defaultOptionIndex: 0,
      options: [
        {label: 'USD', value: 1},
        {label: 'EUR', value: 2},
      ],
    },
    price: {
      type: 'price',
      length: 30,
      required: false,
      label: 'Price',
      topic: 'pricing',
      default: "0.00",
    },
    sku: {
      type: 'string',
      length: 30,
      required: false,
      label: 'SKU',
      topic: 'product_identifiers',
    },
    gtin: {
      type: 'string',
      length: 30,
      required: false,
      label: 'Global Trade Item Number (GTIN)',
      topic: 'product_identifiers',
    },
    mpn: {
      type: 'string',
      length: 30,
      required: false,
      label: 'Manufacturer Part Number (MPN)',
      topic: 'product_identifiers',
    },
    ean: {
      type: 'string',
      length: 30,
      required: false,
      label: 'UPC/EAN',
      topic: 'product_identifiers',
    },
    stock: {
      type: 'number',
      required: false,
      default: 1,
      label: 'Stock',
      topic: 'inventory',
      style: 'w-49',
    },
    // backorder: {
    //   type: 'boolean',
    //   length: 30,
    //   required: false,
    //   label: 'Backorder',
    //   topic: 'inventory',
    // },
    purchasable: {
      type: 'select',
      length: 30,
      required: false,
      label: 'Purchasability',
      topic: 'inventory',
      defaultOptionIndex: 0,
      options: [
        {label: 'Always', value: 'always'},
        {label: 'In stock', value: 'in_stock'},
        // {label: 'Backorder', value: 'backorder'},
      ],
    },
  },
  display(data) {
    return `${data.name}`;
  },
};

export default ProductVariant;
