import React, {useEffect, useState} from "react";

import Product from "../../models/Product";
import {Redirect, useParams} from "react-router-dom";

/**
 * @returns {JSX.Element}
 * @constructor
 */
function ListProductVariantPage() {
  const [product, setProduct] = useState(null);
  const { productId } = useParams();

  useEffect(() => {
    Product.store.findById(productId, false).then((response) => {
      setProduct(response.data);
    });
  }, [productId]);

  return (
    <>
      { product &&
      <Redirect to={`/products/${product.id}/product-variants/${product.variant.id}`} />
      }
    </>
  );
}

export default ListProductVariantPage;