import {createPortal} from 'react-dom';
import {useState} from "react";
import ModelContent from "./ModelContent";

/**
 *
 * @param children
 * @param title
 * @param isOpen
 * @param toggleIsOpen
 * @returns {|null}
 * @constructor
 */
export default function SideModal({ children, title, isOpen = false, toggleIsOpen = {} }) {
  const [ container ] = useState(() => {
    return document.getElementById('root');
  });

  if (!isOpen) return null
  return createPortal(
    <div className="modal top-0 left-0 fixed w-full h-full z-40 flex justify-center">
      <div onClick={toggleIsOpen} className="modal-background absolute top-0 left-0 w-full h-full bg-gray-800 opacity-50" />
      <div className="absolute right-0 w-1/3 h-full">
        <ModelContent title={title}>
          {children}
        </ModelContent>
      </div>
    </div>,
    container)
}