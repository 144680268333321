import productEndpoints from "../lib/endpoints/ProductEndpoints";
import ProductType from "./ProductType";
import Brand from "./Brand";
import Url from "./Url";
import { AiOutlineEyeInvisible, GrStatusGood} from "react-icons/all";
import Collection from "./Collection";

const Product = {
  store: productEndpoints,

  type: 'products',
  modelName: 'Product',
  fields: {
    brand: {
      type: 'string',
      length: 30,
      required: false,
      hidden: true,
      label: 'Brand Name',
      editable: false,
    },
    name: {
      type: 'string',
      length: 30,
      label: 'Name',
    },
    currency_id: {
      type: 'number',
      default: 1,
      hidden: true,
    },
    product_types: {
      type: 'belongsToMany',
      length: 30,
      required: false,
      contentType: Collection,
      label: 'Collection',
    },
    product_type_id: {
      type: 'belongsTo',
      length: 30,
      contentType: ProductType,
      label: 'Product Type'
    },
    brand_id: {
      type: 'belongsTo',
      length: 30,
      required: false,
      contentType: Brand,
      label: 'Brand'
    },
    description: {
      type: 'richText',
      length: 30,
      label: 'Description',
      style: 'w-full',
    },
    uuid: {
      type: 'string',
      editable: false,
      hidden: false,
      label: 'Unique Identifier (UUID)',
      length: 36,
    },
    is_featured: {
      type: 'boolean',
      length: 30,
      required: false,
      editable: true,
      hidden: false,
      label: 'Featured',
      style: 'w-full',
    },
    status: {
      type: 'select',
      length: 30,
      required: false,
      hidden: true,
      label: 'Visibility',
      defaultOptionIndex: 0,
      options: [
        {label: 'Published', value: 'published', icon: GrStatusGood},
        {label: 'Draft', value: 'draft', icon: AiOutlineEyeInvisible},
      ],
    },

    attached_variants: {
      type: 'attachedValues',
      length: 30,
      required: false,
      hidden: true,
    },
    urls: {
      type: 'hasMany',
      required: false,
      contentType: Url,
      hidden: true,
    },

    // external_url: {
    //   type: 'url',
    //   label: 'Url',
    // },
    // influencer_id: {
    //   type: 'belongsTo',
    //   contentType: Influencer,
    //   label: 'Influencer',
    // },
    // category_id: {
    //   type: 'belongsTo',
    //   contentType: Category,
    //   label: 'Category',
    // },
  },
  display(data) {
    return `${data.name}`;
  },
};

export default Product;
