import axios from "./axios";
import signupEndpoints from "./endpoints/SignupEndpoints";
import productEndpoints from "./endpoints/ProductEndpoints";
import productTypeEndpoints from "./endpoints/ProductTypeEndpoints";
import influencerEndpoints from "./endpoints/InfluencerEndpoints";
import attachmentEndpoints from "./endpoints/attachmentEndpoints";
import categoryEndpoints from "./endpoints/CategoryEndpoints";
import brands from "./endpoints/BrandEndpoints";
import customerEndpoints from "./endpoints/CustomerEndpoints";
import productOptionEndpoints from "./endpoints/ProductOptionEndpoints";
import LogoutFunc from "../components/Functional/LogoutFunc";

const baseUrl = process.env.REACT_APP_API_URL;

const api = {
  /**
   * @param email
   * @param password
   * @returns {Promise<AxiosResponse<any>>}
   */
  login(email, password) {
    return axios.post(`${baseUrl}/api/1/sessions`, {
      email, password
    })
  },
  signup: signupEndpoints,
  products: productEndpoints,
  categories: categoryEndpoints,
  influencers: influencerEndpoints,
  attachments: attachmentEndpoints,
  productTypes: productTypeEndpoints,
  productOptions: productOptionEndpoints,
  customers: customerEndpoints,
  brands: brands,
}

export default api;