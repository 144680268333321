import {Controller} from "react-hook-form";
import NumberFormat from "react-number-format";

export default function PriceInput({fieldObject, model, control, Styles, register, registerOptions}) {
  return (
    <Controller
      control={control}
      name={fieldObject.field}
      render={({ field: { onChange, name, value } }) => (
        <>
        <NumberFormat
          prefix={'USD '}
          className={Styles.input}
          name={name}
          value={value}
          thousandSeparator='.'
          decimalSeparator=','
          onValueChange={(values) => {
            const { floatValue } = values;
            onChange(floatValue);
          }}
        />
        </>
      )}
    />
  )
}
