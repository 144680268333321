import Loader from "../Loader";

/**
 *
 * @param processed
 * @param total
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProgressBar({ processed, total }) {
  const percentage = Math.round((processed * 100) / total) + "%";

  return (
    <div className="progress-bar flex flex-column">
      <svg className="flex" id="progress" height="120" width="300">
        <g className="progress-container">
          <line
            x1="0"
            y1="50%"
            x2="100%"
            y2="50%"
            stroke="#efefef"
            strokeWidth="30"
          />
        </g>
        <g className="progress-content">
          <line
            x1="0"
            y1="50%"
            x2={percentage}
            y2="50%"
            stroke="#10B981"
            fill="transparent"
            strokeWidth="28"
          />
        </g>

        <text className="percentage" fill="ab0000" x="45%" y="55%">
          { processed } / { total }
        </text>
      </svg>
    </div>
  );
}
// rgba(16, 185, 129, var(--tw-bg-opacity))