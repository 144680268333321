import {registerOptions, Styles} from "../Forms/StandardForm";
import TextInput from "../Forms/TextInput";
import {classNames} from "../../lib/functions";
import React from "react";
import {first} from "lodash";
import CheckboxInput from "../Forms/CheckboxInput";
import DeleteButton from "../Common/DeleteButton";
import {RiDeleteBinLine} from "react-icons/all";

/**
 *
 * @param url
 * @param index
 * @param groupedFormFields formFields grouped by field. 'field_key' => [ {fields} ]
 * @param register
 * @returns {JSX.Element}
 * @constructor
 */
export default function UrlFormItem({ urls, control, getValues, setValue, remove, index, formFields: groupedFormFields, register }) {
  const slugFieldObject = first(groupedFormFields.slug);
  const defaultFieldObject = first(groupedFormFields.default);
  const fieldKey = 'urls';

  return (
    <>
      <div className="w-64">
        <select
          className="form-select block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <option value="1">English</option>
        </select>
      </div>

      <div className="w-full">
        <TextInput
          className={classNames(Styles.input)}
          data={urls}
          fieldObject={slugFieldObject}
          register={register}
          registerOptions={registerOptions}
          fieldKey={`${fieldKey}.${index}.slug`}
        />
      </div>

      <div className="flex items-center w-32 space-x-4">
        <>
          <CheckboxInput
            className={classNames(Styles.input)}
            control={control}
            data={urls}
            fieldObject={defaultFieldObject}
            register={register}
            registerOptions={registerOptions}
            fieldKey={`${fieldKey}.${index}.default`}
            value={getValues(`${fieldKey}.${index}.default`)}
            getValues={getValues}
            setValue={(value) => {
              setValue(`${fieldKey}.${index}.default`, value);
            }}
          />
        </>

          <DeleteButton
            className={"ml-5"}
            type="button"
            icon={RiDeleteBinLine}
            onClick={ () => remove(index)  }
          />
        {/*<button className="text-gray-400">*/}
        {/*  <div>*/}
        {/*    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
        {/*      <path fillRule="evenodd" clipRule="evenodd"*/}
        {/*            d="M9 2C8.62123 2 8.27497 2.214 8.10557 2.55279L7.38197 4H4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6L4 16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16V6C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H12.618L11.8944 2.55279C11.725 2.214 11.3788 2 11 2H9ZM7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8V14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14V8ZM12 7C11.4477 7 11 7.44772 11 8V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V8C13 7.44772 12.5523 7 12 7Z"*/}
        {/*            fill="currentColor"/>*/}
        {/*    </svg>*/}
        {/*  </div>*/}
        {/*</button>*/}
      </div>
    </>
  );
}