import axios from "../axios";
const baseUrl = process.env.REACT_APP_API_URL;

const signupEndpoints = {
  /**
   *
   * @returns {Promise<AxiosResponse<any>>}
   * @param userData
   */
  signup(userData) {
    return axios.post(`${baseUrl}/api/1/signup`, userData);
  },
}

export default signupEndpoints;