import Button from "../Common/Button";
import React from "react";
import {Styles} from "./StandardForm";
import {classNames} from "../../lib/functions";

export default function SaveBar({ children, formRef, formFields, data }) {

  return (
    <div className={classNames('pb-5 flex justify-end gap-2')}>
      {children &&
      <div className="w-52">
        {children}
      </div>
      }

      <Button onClick={() => formRef.current.submit()}
              className={classNames('flex', Styles.save)}
              text={'Save'}
              type="submit"
      />
    </div>
  );
}