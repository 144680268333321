import {Route, Redirect} from "react-router-dom";
import { useContext } from "react";
import {UserContext} from "../contexts/UserContext";

function AuthRoute({component: Component, ...params}) {
  const { userContext } = useContext(UserContext);

  return (
    <Route {...params} render={(props) => userContext?.user ?
             <Component {...props} /> :
             <Redirect to={{pathname: '/login', state: {from: props.location}}} />
    }
    />
  );
}

export default AuthRoute;