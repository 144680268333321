import BaseEndpoints from "../lib/endpoints/BaseEndpoints";

const Featured = {
  store: new BaseEndpoints('featured'),

  type: 'featured',
  modelName: 'Featured',
  fields: {
  },
};

export default Featured;
