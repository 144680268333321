import React, {useEffect, useState} from "react";

import Product from "../../models/Product";
import {Link, Redirect, useParams} from "react-router-dom";
import {usePagination} from "../../lib/pagination-helpers";
import {useOnClickRow} from "../../lib/table-helpers";
import NumberFormat from "react-number-format";
import Layout from "../../components/Layout";
import Content from "../../components/Content";
import SubmitButton from "../../components/Common/SubmitButton";
import Table from "../../components/Table";
import Collection from "../../models/Collection";

/**
 * @returns {JSX.Element}
 * @constructor
 */
function ListCollections() {
  const { data, url, history, paginationInfo } = usePagination(Collection);
  const { onClickRow } = useOnClickRow(data, history, url);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      // {
      //   Header: 'Status',
      //   accessor: 'status',
      // },
    ],[]
  )

  return (
    <Layout>
      <Content className="list-products">
        <div className="w-full content-header flex justify-between mb-5">
          <h1 className="block">Collections</h1>
          <div className="actions float-right">
            <Link to={`${url}/add/`}>
              <SubmitButton className='add-new-button' text={'Add new'} />
            </Link>
          </div>
        </div>
        <div className="w-full">
          <Table data={data}
                 isLoading={data === null}
                 onClickRow={onClickRow}
                 paginationInfo={paginationInfo}
                 columns={columns}
          />
        </div>
      </Content>
    </Layout>
  )
}

export default ListCollections;