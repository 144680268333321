import Loader from "./Loader";
import {Slot} from "./Common/Slot";

/**
 *
 * @param children
 * @param title
 * @param isLoading
 * @param actionSlot
 * @returns {JSX.Element}
 * @constructor
 */
export default function ContentCard({ children, title = null, isLoading = false, actionSlot = null }) {
  return (
    <div className="bg-white rounded-md border mb-5 border-gray-200">
      {isLoading &&
      <div className="relative">
        <div className="absolute right-0 px-5 py-2">
          <Loader/>
        </div>
      </div>
      }
      <div className="py-8 px-8">
        {title &&
        <header className="mb-4 flex justify-between">
          <span className="text-lg font-medium leading-6 text-gray-900">{title}</span>
          <div className="card-actions flex">
            <Slot content={actionSlot} />
          </div>
        </header>
        }
        <>
          {children}
        </>
      </div>
    </div>
  );
}
