import {useContext} from "react";
import {UserContext} from "../../contexts/UserContext";
import Layout from "../../components/Layout";
import Content from "../../components/Content";

function Admin() {
  const { user } = useContext(UserContext);

  return (
    <Layout>
      <Content>
        <div className="flex content-start">
          <h1 className="block font-light">Dashboard</h1>
        </div>
      </Content>
    </Layout>
  )
}

export default Admin;