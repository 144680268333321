import React from 'react';
// import { nav} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import {useForm} from "react-hook-form";

import api from '../../lib/api';
import Container from "../../components/Container";

function SignupPage() {
  const history = useHistory();
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    await api.signup.signup(data);

    history.replace('/login', [{ email: data.email }]);
  }

  return (
  <Container>
    <div className="flex justify-center mt-60">
      <div className="w-full max-w-xs">
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={ handleSubmit(onSubmit) }>
          <h1>Signup</h1>
          <div className="mb-4 mt-4">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username" type="email" placeholder="Email"
              {...register("email", { required: true })}
            />
          </div>
          <div className="mb-4 mt-4">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="firstname" type="text" placeholder="Firstname"
              {...register("firstname", { required: true })}
            />
          </div>
          <div className="mb-4 mt-4">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="firstname" type="text" placeholder="Lastname"
              {...register("lastname", { required: true })}
            />
          </div>
          <div className="mb-4 mt-4">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="signup_token" type="text" placeholder="Signup Token"
              {...register("signup_token", { required: true })}
            />
          </div>
          <div className="mb-6">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password" type="password" placeholder="******************"
              {...register("password", { required: true })}
            />
          </div>
          <div className="flex flex-row-reverse">
            <button type="submit" className="py-2 px-4 bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75">Login</button>
          </div>
        </form>
        <p className="text-center text-gray-500 text-xs">
          &copy;2022 Lento. All rights reserved.
        </p>
      </div>
    </div>
  </Container>
);
}

export default SignupPage;
