import React, {useRef, useState} from "react";
import Layout from "../../components/Layout";
import Content from "../../components/Content";
import ContentCard from "../../components/ContentCard";
import StandardForm from "../../components/Forms/StandardForm";
import Product from "../../models/Product";
import {toFormFields} from "../../lib/functions";
import AttachmentUploads from "../../components/Attachments/AttachmentUploads";
import {useEditHelpers} from "../../lib/edit-helpers";
import BackButton from "../../components/Common/BackButton";
import IF from "../../components/Common/IF";
import ProductVariantsTable from "../../components/ProductVariantsTable";
import {useHistory} from "react-router-dom";
import Featured from "../../models/Featured";
import AddUrlsForm from "../../components/Urls/AddUrlsForm";
import SaveBar from "../../components/Forms/SaveBar";
import BottomOverlayBar from "../../components/BottomOverlayBar";
import DeleteCard from "../../components/DeleteCard";
import SelectSearchBox from "../../components/Inputs/SelectSearchBox";

function EditProductsPage() {
  const history = useHistory();
  const { data, onSubmit, onDelete, isLoading, refresh } = useEditHelpers(Product);
  const formRef = useRef();

  const handleDeactivate = () => {
    history.push(`/products`);
  }

  const formFields = toFormFields(Product);

  const statusFormField = formFields.find((formField) => {
    return formField.field === 'status';
  });

  return (
    <Layout>
      <Content className="add-products pb-32" isLoading={isLoading}>
        {data &&
        <>
          <div className="content-header w-full flex justify-between mb-5">
            <div className="flex">
              <BackButton onClick={handleDeactivate}/>
              <h1 className="block">Edit Product</h1>
            </div>
          </div>

          <div className={"flex justify-end"}>
            <span>Status:</span><span className={'font-bold'}>{data.status}</span>
          </div>

          <ContentCard isLoading={isLoading}>
            <StandardForm formFields={formFields}
                          hideSaveButtons={true}
                          ref={formRef}
                          onSubmit={onSubmit}
                          handleDeactivate={handleDeactivate}
                          model={data}
            />
          </ContentCard>

          <ContentCard title="URLs">
            {formRef.current &&
            <AddUrlsForm formRef={formRef} model={data} ownerModel={Product}/>
            }
          </ContentCard>

          {/*<IF condition={data.variants.length >= 1}>*/}
          {/*  <ContentCard title="Variants" actionSlot={ProductVariantsCardActions(hasVariants, setHasVariants)}>*/}
          {/*    <ProductOptionsForm*/}
          {/*      saveProductRef={refFrom}*/}
          {/*      setIsLoading={setIsLoading}*/}
          {/*      hasVariants={hasVariants}*/}
          {/*      product={data}*/}
          {/*      refresh={loadData}*/}
          {/*    />*/}
          {/*  </ContentCard>*/}
          {/*</IF>*/}

          <IF condition={data.variants.length >= 1}>
            <ContentCard title="Variants">
              <ProductVariantsTable product={data}/>
            </ContentCard>
          </IF>

          {/*<ContentCard title="Pricing" actionSlot={ProductPricingCardActions()}>*/}
          {/*  <PricingForm productVariant={data.variant} setIsLoading={setIsLoading}/>*/}
          {/*</ContentCard>*/}

          {/*<ContentCard title="Product Identifiers" >*/}
          {/*  <ProductIdentifiersForm productVariant={data.variant} setIsLoading={setIsLoading} />*/}
          {/*</ContentCard>*/}

          {/*<ContentCard title="Inventory" >*/}
          {/*  <InventoryForm productVariant={data.variant} setIsLoading={setIsLoading}/>*/}
          {/*</ContentCard>*/}

          {/*<ContentCard title="Shipping" >*/}
          {/*</ContentCard>*/}

          <ContentCard title="Image uploads" isLoading={isLoading}>
            <AttachmentUploads ownerModel={Product} model={data} refresh={refresh}/>
          </ContentCard>
          {/*<DeleteCard onDelete={onDelete} />*/}
          {data.is_featured &&
          <ContentCard title="Featured Images" isLoading={isLoading}>
            <AttachmentUploads ownerModel={Featured} model={data.featured} refresh={refresh}/>
          </ContentCard>
          }
          <DeleteCard onDelete={onDelete}/>
        </>
        }
      </Content>
      <BottomOverlayBar
        isOpen={true}>
        <SaveBar
          formFields={formFields}
          formRef={formRef}
          model={data}
        >
          {formRef.current &&
          <SelectSearchBox
            {...formRef.current.register(statusFormField.field, formRef.current.registerOptions(statusFormField, data))}
            options={statusFormField.options}
            isClearable={false}
            defaultOptionIndex={statusFormField.defaultOptionIndex}
            value={formRef.current.getValues(statusFormField.field)}
            findSelectedOption={statusFormField.findSelectedOption}
            setValue={(value) => {
              formRef.current.setValue(statusFormField.field, value);
            }}
          />
          }
        </SaveBar>
      </BottomOverlayBar>
    </Layout>
  );
}

export default EditProductsPage;