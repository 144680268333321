import Button from "../Common/Button";
import {FiPlus} from "react-icons/all";
import {useState} from "react";
import SideModal from "../SideModal";
import OptionList from "../Common/OptionList";
import SaveButton from "../Common/SaveButton";

/**
 * @returns {JSX.Element}
 * @constructor
 */
export default function AddProductOptionsContainer({ selectedOptions, toggleSelectedOption, productOptionsAvailable }) {
  const [isOpenAddNewPortal, setIsOpenAddNewPortal] = useState(false);

  const toggleAddProductOption = () => {
    setIsOpenAddNewPortal(!isOpenAddNewPortal);
  }

  return (
    <div>
      <Button icon={FiPlus} text={"Add Option"} onClick={ toggleAddProductOption } />
      <SideModal title={"Select an Option"} isOpen={isOpenAddNewPortal} toggleIsOpen={toggleAddProductOption} >
        <OptionList
          optionsAvailable={productOptionsAvailable}
          selectedOptions={selectedOptions}
          toggleSelectedOption={toggleSelectedOption}
        />

        <div className='absolute bottom-10 right-5'>
          <SaveButton onClick={() => {}} text="Use selected options" onCLick={toggleAddProductOption} />
        </div>
      </SideModal>
    </div>
  );
}