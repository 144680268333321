/**
 *
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export default function Container({ children }) {
  return (
    <div className="container h-screen pb-10">
      {children}
    </div>
  );
}
