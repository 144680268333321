import productOptionEndpoints from "../lib/endpoints/ProductOptionEndpoints";
import ProductOptionValue from "./ProductOptionValue";
import ProductType from "./ProductType";

const ProductOption = {
  store: productOptionEndpoints,

  type: 'product_variants',
  modelName: 'ProductOption',
  fields: {
    name: {
      type: 'string',
      length: 30,
      required: true,
      label: 'Name',
      style: "w-49",
    },
    label: {
      type: 'string',
      length: 30,
      required: false,
      label: 'Label',
      style: "w-49",
    },
    product_types: {
      type: 'belongsToMany',
      length: 30,
      required: false,
      contentType: ProductType,
      label: 'Product Types',
      style: 'w-full',
    },
    values: {
      type: 'hasMany',
      contentType: ProductOptionValue,
      label: 'Option values',
      style: "w-full",
    }
  },
  display(data) {
    return `${data.name}`;
  },
};

export default ProductOption;
