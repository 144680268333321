import IF from "../Common/IF";
import {classNames} from "../../lib/functions";
import PriceInput from "../Inputs/PriceInput";
import SelectSearchBox from "../Inputs/SelectSearchBox";
import AsyncSelectBox from "../AsyncSelectBox";
import {isEmpty} from "lodash";
import {registerOptions, Styles} from "./StandardForm";
import TextInput from "./TextInput";
import NumberInput from "./NumberInput";
import UrlInput from "./UrlInput";
import HasManyInput from "./HasManyInput";
import React from "react";
import TextAreaInput from "./TextAreaInput";
import CheckboxInput from "./CheckboxInput";
import RichTextEditorInput from "../Inputs/RichTextEditorInput";
import FileUploadInput from "./FileUploadInput";

/**
 * @param errors
 * @param fieldObject
 * @returns {*|string}
 */
const toError = (errors, fieldObject) => {
  const fieldKey = fieldObject.field;

  let type = errors[fieldKey] ? errors[fieldKey].type: '';

  if (type) {
    return `This is field is ${type}`;
  } else {
    return '';
  }
};


/**
 * @param fieldObject
 * @param index
 * @param getValues
 * @param setValue
 * @param errors
 * @param registerOptions
 * @param register
 * @param watch
 * @param control
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
export default function FormField({ fieldObject, index, getValues, setValue, errors, registerOptions, register, watch, control, data }) {
  return (
    <>
      <IF key={'field' + index} condition={!fieldObject.hidden}>
        <div className={classNames(Styles.inputRow, fieldObject.style ?? Styles.halfWidth)} key={'div' + index} >
          <label className="flex uppercase text-gray-800 text-sm">{fieldObject.label}<span>{ fieldObject.required ? '*': ''}</span></label>
          { fieldObject.type === 'text' &&
          <TextInput
            className={ classNames(Styles.input) }
            data={data}
            fieldObject={fieldObject}
            register={register}
            registerOptions={registerOptions}
          />
          }
          { fieldObject.type === 'richText' &&
          <RichTextEditorInput
            className={ classNames(Styles.input) }
            control={control}
            data={data}
            fieldObject={fieldObject}
            register={register}
            registerOptions={registerOptions}
            value={getValues(fieldObject.field)}
            getValues={getValues}
            setValue={(value) => {
              setValue(fieldObject.field, value);
            }}
          />
          }
          { fieldObject.type === 'fileUpload' &&
          <FileUploadInput
            className={ classNames(Styles.input) }
            data={data}
            fieldObject={fieldObject}
            register={register}
            registerOptions={registerOptions}
          />
          }
          { fieldObject.type === 'textarea' &&
          <TextAreaInput
            className={ classNames(Styles.input) }
            data={data}
            fieldObject={fieldObject}
            register={register}
            registerOptions={registerOptions}
          />
          }
          { fieldObject.type === 'price' &&
          <PriceInput
            Styles={Styles}
            control={control}
            fieldObject={fieldObject}
            model={data}
            register={register}
            registerOptions={registerOptions}
          />
          }
          {fieldObject.type === 'number' &&
          <NumberInput
            className={ classNames(Styles.input) }
            data={data}
            fieldObject={fieldObject}
            register={register}
            registerOptions={registerOptions}
          />
          }
          {fieldObject.type === 'url' &&
          <UrlInput
            fieldObject={fieldObject}
            register={register}
            className={ classNames(Styles.urlInput) }
            registerOptions={registerOptions}
            data={data} />
          }
          {fieldObject.type === 'email' &&
          <UrlInput
            fieldObject={fieldObject}
            register={register}
            className={ classNames(Styles.input) }
            registerOptions={registerOptions}
            data={data} />
          }
          { fieldObject.type === 'hasMany' &&
          <HasManyInput
            fieldObject={fieldObject}
            index={index}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            registerOptions={registerOptions}
            className={Styles.input}
            register={register}
            watch={watch}
            control={control}
            data={getValues(fieldObject.field)}
          />
          }
          {fieldObject.type === 'select' &&
          <SelectSearchBox
            options={fieldObject.options}
            defaultOptionIndex={fieldObject.defaultOptionIndex}
            v={getValues(fieldObject.field)}
            value={getValues(fieldObject.field)}
            field={fieldObject.field}
            findSelectedOption={fieldObject.findSelectedOption}
            findOptions={fieldObject.findOptions}
            watch={watch}
            getValues={getValues}
            setValue={(value) => {
              setValue(fieldObject.field, value);
            }}
          />
          }
          { fieldObject.type === 'select-list-belongs-to' &&
          <>
            <AsyncSelectBox
              {...register(fieldObject.field, registerOptions(fieldObject, data))}
              model={data}
              isDisabled={fieldObject.editable === false}
              ownerModel={fieldObject.ownerModel}
              setValue={(value) => {
                setValue(fieldObject.field, value);
              }}
              isRequired={fieldObject.required}
              findSelectedOption={fieldObject.findOwner}
              findOptions={fieldObject.findOptions}
              field={fieldObject.field}
              control={control}
              watch={watch}
            />
          </>
          }
          { fieldObject.type === 'select-list-belongs-to-many' &&
          <>
            <AsyncSelectBox
              {...register(fieldObject.field, registerOptions(fieldObject, data))}
              isMulti={true}
              model={data}
              isDisabled={fieldObject.editable === false}
              ownerModel={fieldObject.ownerModel}
              setValue={(value) => {
                setValue(fieldObject.field, value);
              }}
              isRequired={fieldObject.required}
              findSelectedOption={fieldObject.findOwners}
              findOptions={fieldObject.findOptions}
              field={fieldObject.field}
              control={control}
              watch={watch}
            />
          </>
          }
          {fieldObject.type === 'checkbox' &&
            <CheckboxInput
              className={classNames(Styles.input)}
              control={control}
              data={data}
              fieldObject={fieldObject}
              register={register}
              registerOptions={registerOptions}
              value={getValues(fieldObject.field)}
              getValues={getValues}
              setValue={(value) => {
                setValue(fieldObject.field, value);
              }}
            />

          // <input type='checkbox'
          //        {...register(fieldObject.field, registerOptions(fieldObject, data))}
          // />
          }
          { !isEmpty(errors) &&
          <span className={Styles.errors}>{toError(errors, fieldObject)}</span>
          }
        </div>
      </IF>
    </>
  );
}
