import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {getPaginationInfoFromData} from "./functions";
import {useEffect, useState} from "react";

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

export const usePagination = (modelClass) => {
  const { url, path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();
  const page = query.get('page');

  const [ data, setData ] = useState(null);
  const [ paginationInfo, setPaginationInfo ] = useState(null);

  useEffect(function() {
    const params = {
      page: page,
    }

    modelClass.store.get(params).then((response) => {
      const data = response.data;
      const paginationInfo = getPaginationInfoFromData(data);

      setData(data.data);
      setPaginationInfo(paginationInfo);
    })
  }, [location, page]);

  return { data, path, url, history, paginationInfo }

}
