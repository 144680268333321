import Loader from "./Loader";
import {classNames} from "../lib/functions";

/**
 *
 * @param children
 * @param className
 * @param isLoading
 * @returns {JSX.Element}
 * @constructor
 */
export default function Content({ children, className= null, isLoading = false }) {
  return (
    <>
      <div className={classNames(className, "content pb-42 flex w-full overflow-hidden border-b border-gray-200 shadow sm:rounded-lg")}>
        <div className="w-full lg:8/12 mt-5 p-4">
          <div className="max-w-6xl">
            {isLoading &&
            <div className="relative">
              <div className="absolute right-0 px-5 py-2">
                <Loader />
              </div>
            </div>
            }
          {children}
          </div>
        </div>
      </div>
    </>
);
}
