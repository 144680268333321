import {forwardRef, useCallback, useEffect, useState} from 'react'
import AsyncSelect from 'react-select/async';
import {isArray, isEmpty} from "lodash";


/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AsyncSelectBox = forwardRef((props, ref) => {
  const {
    field,
    findOptions,
    findSelectedOption,
    isDisabled = false,
    isMulti = false,
    isRequired,
    ownerModel,
    setValue,
    watch,
  } = props ;

  const currentValue = watch(field);

  let unselectedOption = [];

  if (!isMulti) {
    unselectedOption = { label: '-- Unselected --', value: null};
  }

  const [selectedOption, setSelectedOption] = useState(unselectedOption);

  const [isLoaded, setIsLoaded] = useState(true);
  const [searchText, setSearchText] = useState(null);

  // Loads the current selected object.
  useEffect(function() {
    const fetchData = async () => {
      if (!currentValue || (isMulti && isEmpty(currentValue))) {
        return;
      }

      setIsLoaded(false);
      const response = await findSelectedOption(currentValue);
      setIsLoaded(true);

      if (isMulti && isArray(response.data.data)) {
        const selectedFormatted = response.data.data.map((value) => {
          return {
            value: value.id,
            label: ownerModel.display(value),
          }
        })

        setSelectedOption(selectedFormatted);
      } else {
        const selectedFormatted = {
          value: response.data.id,
          label: ownerModel.display(response.data),
        };

        setSelectedOption(selectedFormatted);
      }
    }

    return fetchData();
  }, [currentValue, findSelectedOption, field]);

  // Loads the available optionos.
  const loadOptions = useCallback(async () => {
    setIsLoaded(false);
    const options = await findOptions(searchText);
    setIsLoaded(true);

    return options.data.map((data) => {
      return {
        value: data.id,
        label: ownerModel.display(data),
      }
    });

  }, [searchText]);

  const optionSelected = (value) => {
    if (value === null) {
      setValue(null);
      setSelectedOption(unselectedOption);
      value = unselectedOption;
    } else {
      if (isArray(value)) {
        setValue(value.map(v => v.value));
      } else {
        setValue(value.value);
      }

      setSelectedOption(value);
    }

    return value;
  };

  // Triggered on user input typing.
  const handleInputChange = async (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    setSearchText(inputValue);
    return inputValue;
  };

  return (
    <AsyncSelect
      isMulti={isMulti}
      isLoading={!isLoaded}
      isClearable={isRequired === false}
      isDisabled={isDisabled}
      value={selectedOption}
      defaultValue={selectedOption.value}
      onChange={optionSelected}
      loadOptions={loadOptions}
      onInputChange={handleInputChange}
      defaultOptions
    />
  )
});

export default AsyncSelectBox;