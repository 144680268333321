import TaxClassEndpoints from "../lib/endpoints/TaxClassEndpoints";

const TaxClass = {
  store: TaxClassEndpoints,

  type: 'tax-classes',
  modelName: 'TaxClass',
  fields: {
    name: {
      type: 'string',
      length: 30,
      required: true,
      label: 'Name',
    },
  },
  display(data) {
    return `${data.name}`;
  },
};

export default TaxClass;
