import {classNames} from "../../lib/functions";
import {isEmpty} from "lodash";
import IF from "../Common/IF";

/**
 * @param onClick
 * @param Style
 * @param index
 * @param activeStyle
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProductVariantValuesSideNavItem({onClick, Style, activeStyle, item}) {
  return (
    <>
      <IF condition={!isEmpty(item.values)}>
        <a onClick={() => onClick(item)} className={classNames(Style.aItem, activeStyle)}>
          {item.values.map((o) => `${o.variant_name}`).join(' / ')}
        </a>
      </IF>
      <IF condition={isEmpty(item.values)}>
        <a onClick={() => onClick(item)} className={classNames(Style.aItem, activeStyle)}>
          Default variant
        </a>
      </IF>
    </>
    );
}