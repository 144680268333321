import React from "react";
import {Link, Route, Switch} from "react-router-dom";

import Layout from "../../components/Layout";
import SubmitButton from '../../components/Common/SubmitButton';

import Content from "../../components/Content";
import Table from '../../components/Table';
import EditBrandPage from "./EditBrandPage";
import AddBrandPage from "./AddBrandPage";
import {usePagination} from "../../lib/pagination-helpers";
import {useOnClickRow} from "../../lib/table-helpers";
import Brand from "../../models/Brand";

/**
 * @returns {JSX.Element}
 * @constructor
 */
function ListBrandPage() {
  const { data, path, url, history, paginationInfo } = usePagination(Brand);
  const { onClickRow } = useOnClickRow(data, history, url);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Featured',
        accessor: (value) => {
          return value.featured ? 'yes': 'no';
        },
      },
      {
        Header: 'action',
        accessor: (value) => {
          return value;
        },
        Cell: ({value}) => (
          <Link className="hover:text-gray-500"
            to={{
              pathname:`${url}/edit/${value.id}`,
              state: { model: value }
            }}>Edit</Link>
        )
      },
    ],[url]
  )

  return (
    <Layout>
      <Content className="list">
        <div>
          <div className="w-full content-header flex justify-between mb-5">
            <h1 className="block">Brands</h1>
            <div className="actions float-right">
              <Link to={`${url}/add/`}>
                <SubmitButton className='add-new-button' text={'Add new'} />
              </Link>
            </div>
          </div>
          <div className="w-full">
            <Table data={data} isLoading={data === null} onClickRow={onClickRow} paginationInfo={paginationInfo} columns={columns} />
          </div>
        </div>
      </Content>

      <Switch>
        <Route path={`${path}/edit/:brandId`}>
          <EditBrandPage />
        </Route>
        <Route path={`${path}/add`}>
          <AddBrandPage />
        </Route>
      </Switch>

    </Layout>
  )
}

export default ListBrandPage;