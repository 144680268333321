import {useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import {toastWithMessage} from "./toast-helpers";

/**
 * @param modelClass
 * @returns {{isLoading: boolean, path: string, onDelete: (function(): *), data: unknown, onSubmit: (function(*=): Promise<AxiosResponse<*>>), handleDeactivate: handleDeactivate, loadData: (function(): Promise<unknown>), history: History<LocationState>, url: string}}
 */
export const useEditHelpers = (modelClass) => {
  const history = useHistory();

  const { state } = useLocation();

  const { url, path } = useRouteMatch();
  const [ isLoading, setIsLoading] = useState(true);
  const [ data, setData ] = useState(null);

  const { id } = useParams();

  const handleDeactivate = () => {
    history.push(state?.parentPath ?? '/' + modelClass.type);
  }

  const loadData = (useCache = true) => {
    setIsLoading(true)

    return modelClass.store.findById(id, useCache).then((response) => {
      setData(response.data);

      return response.data;
    }).finally(() => {
      setIsLoading(false);
    })
  };

  const refresh = () => {
    return loadData(false);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);

    return modelClass.store.edit(id, data).then(() => {
      loadData();

      toastWithMessage(`${modelClass.modelName} updated`);
    }).finally(() => {
      setIsLoading(false);
    })
  };

  const onDelete = async () => {
    return modelClass.store.delete(data.id).then(() => {
      handleDeactivate();
    })
  }

  useEffect(() => {
    return loadData();
  }, [url, id]);

  return {
    id,
    data,
    handleDeactivate,
    history,
    isLoading,
    loadData,
    onDelete,
    onSubmit,
    path,
    refresh,
    setData,
    setIsLoading,
    url,
  }
};
