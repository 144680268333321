import UrlEndpoints from "../lib/endpoints/UrlEndpoints";
import {FiPlus} from "react-icons/all";

const Url = {
  store: UrlEndpoints,

  type: 'url',
  fields: {
    id: {
      type: 'number',
      length: 255,
      required: true,
      label: 'Name',
      hidden: false,
    },
    slug: {
      type: 'string',
      length: 255,
      required: true,
      label: 'slug',
      placeholder: 'the-brand-and-the-product-name',
      hidden: false,
    },
    default: {
      type: 'boolean',
      required: false,
      label: 'Default',
      hidden: false,
    },
  },
  display(data) {
    return `${data.name}`;
  },
};

export default Url;
