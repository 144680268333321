import React from "react";
import {Link} from "react-router-dom";

import Layout from "../../components/Layout";
import SubmitButton from '../../components/Common/SubmitButton';

import Content from "../../components/Content";
import Table from '../../components/Table';
import Product from "../../models/Product";
import {usePagination} from "../../lib/pagination-helpers";
import {useOnClickRow} from "../../lib/table-helpers";
import NumberFormat from "react-number-format";

/**
 * @returns {JSX.Element}
 * @constructor
 */
function ListProductsPage() {
  const { data, url, history, paginationInfo } = usePagination(Product);
  const { onClickRow } = useOnClickRow(data, history, url);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Brand',
        accessor: 'brand',
      },
      {
        accessor: (value) => {
          return value;
        },
        Header: 'Price',
        Cell: ({value}) => {
          return <NumberFormat value={value?.variant?.price || null}
                               displayType={'text'}
                               thousandSeparator='.'
                               decimalSeparator=','
                               prefix={'USD '} />
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],[]
  )

  return (
    <Layout>
      <Content className="list-products">
        <div className="w-full content-header flex justify-between mb-5">
          <h1 className="block">Products</h1>
          <div className="actions float-right">
            <Link to={`${url}/add/`}>
              <SubmitButton className='add-new-button' text={'Add new'} />
            </Link>
          </div>
        </div>
        <div className="w-full">
          <Table data={data}
                 isLoading={data === null}
                 onClickRow={onClickRow}
                 paginationInfo={paginationInfo}
                 columns={columns} />
        </div>
      </Content>
    </Layout>
)
}

export default ListProductsPage;