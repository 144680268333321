import React from "react";
import {useHistory} from "react-router-dom";
import StandardForm from "../../components/Forms/StandardForm";
import Content from "../../components/Content";
import ContentCard from "../../components/ContentCard";
import Layout from "../../components/Layout";
import {toFormFields} from "../../lib/functions";
import Collection from "../../models/Collection";

/**
 * @returns {JSX.Element}
 * @constructor
 */
function AddCollections() {
  const history = useHistory();

  const onSubmit = async (data) => {
    return Collection.store.add(data).then(() => {
      handleDeactivate();
    })
  }

  function handleDeactivate() {
    history.goBack();
  }

  return (
    <Layout>
      <Content className="add-product-types">
        <div>
          <div className="content-header w-full flex justify-between mb-5">
            <h1 className="block">Add {Collection.modelName}</h1>
            <div className="actions float-right"></div>
          </div>
          <ContentCard>
            <StandardForm formFields={toFormFields(Collection)}
                          onSubmit={onSubmit}
                          handleDeactivate={handleDeactivate}
            />
          </ContentCard>
        </div>
      </Content>
    </Layout>
  );
}

export default AddCollections;