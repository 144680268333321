/**
 *
 * @param fieldObject
 * @param register
 * @param registerOptions
 * @param className
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
export default function NumberInput({fieldObject, register, registerOptions, className, data}) {
 return (
   <input type="number"
          placeholder={fieldObject.placeholder}
          disabled={ fieldObject.editable === false }
          className={className}
          {...register(fieldObject.field, registerOptions(fieldObject, data)) }
   />
 );
}