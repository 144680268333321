import Select, { components } from "react-select";
import React, {forwardRef, useEffect, useState} from "react";

const { Option, Control } = components;

const OptionWithIcon = props => (
  <Option {...props}>
    <div className="flex items-center gap-2">
      {props.data.icon && React.createElement(props.data.icon) }
      {props.data.label}
    </div>
  </Option>
);

const ControlWithIcon = ({ children, ...props }) => {
  const value = props.getValue();
  const selectedValue = value ? value[0]: value;

  return (
    <components.Control {...props}>
      <div className="flex w-full items-center pl-2">
        {selectedValue?.icon && React.createElement(selectedValue.icon) }
         {children}
      </div>
    </components.Control>
  );
};

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SelectSearchBox = function({
                                      isDisabled = false,
                                      isClearable = true,
                                      isSearchable = true,
  v,
                                      defaultOptionIndex = null,
                                      options = [],
                                      findSelectedOption = null,
                                      watch = null,
                                      getValues,
                                      field,
                                      value = null,
                                      setValue,
                                    }) {

  if (watch) {
    value = watch(field);
  }

  const unselectedOption = {label: '-- Unselected --', value: null};
  const defaultOption = defaultOptionIndex >= 0 ? (options[defaultOptionIndex] ?? unselectedOption) : unselectedOption;

  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const currentValue = findSelectedOption ? findSelectedOption(value) : null;

    setSelectedOption(currentValue);
  }, [value]);

  const optionSelected = (value) => {
    if (!value) {
      setValue(null);
      setSelectedOption(unselectedOption);
      value = unselectedOption;
    } else {
      setValue(value.value);
      setSelectedOption(value);
    }

    return value;
  };

  return (
    <Select
      styles={{zIndex: 9999}}
      className="select-search-box"
      menuPlacement={'auto'}
      classNamePrefix="select"
      defaultValue={defaultOption}
      value={selectedOption}
      isDisabled={isDisabled}
      isClearable={isClearable}
      isSearchable={isSearchable}
      options={options}
      components={{Control: ControlWithIcon, Option: OptionWithIcon}}
      onChange={optionSelected}
    />
  );
}

export default SelectSearchBox;