
export default function FileUploadInput({model, fieldObject, register, registerOptions, className, data}) {
  const file = data[fieldObject.field];

 return (
   <h1>{file}</h1>
   // <SIngleFileUpload
   //   model={model}
   // />

   // <input type="email"
   //        placeholder={fieldObject.placeholder}
   //        disabled={ fieldObject.editable === false } className={className}
   //        {...register(fieldObject.field, registerOptions(fieldObject, data)) }
   // />
 );
}