import BaseEndpoints from "./BaseEndpoints";
import axios from "../axios";

export const resource = 'attributes';

class AttributeEndpoints extends BaseEndpoints {
  listFor(modelType, options = {}) {
    return axios.get(`${this.baseUrl}/api/1/${this.resource}/${modelType}`, options);
  }
}

export default new AttributeEndpoints(resource);