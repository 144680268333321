import {toFormFields} from "../../lib/functions";
import StandardForm from "../Forms/StandardForm";
import ProductOption from "../../models/ProductOption";

/**
 * @returns {JSX.Element}
 * @constructor
 */
export default function EditProductOptionForm({ option, handleDeactivate }) {
  const onSubmit = (data) => {
    return ProductOption.store.edit(option.id, data).then(handleDeactivate);
  }

  const onDelete = () => {
    return ProductOption.store.delete(option.id).then(handleDeactivate);
  }

  return (
    <>
      <StandardForm formFields={toFormFields(ProductOption)}
                    onSubmit={onSubmit}
                    handleDeactivate={handleDeactivate}
                    model={option}
      />
    </>
  );
}