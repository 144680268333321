import BaseEndpoints from "./BaseEndpoints";
import axios from "../axios";
import {toastWithMessage} from "../toast-helpers";

export const resource = 'products';

class ProductEndpoints extends BaseEndpoints {
  createVariants(productId, data) {
    return axios.post(`${this.baseUrl}/api/1/${this.resource}/${productId}/create_variants`, data)
      .catch((e) => {
        toastWithMessage(e.response.data.message)
        throw e;
      });
  }
}

export default new ProductEndpoints(resource);
