import React from "react";

import Layout from "../../components/Layout";
import Content from "../../components/Content";

/**
 * @returns {JSX.Element}
 * @constructor
 */
function NotFoundPage() {
  return (
    <Layout>
      <Content className="list-products">
        <span>The page you requested cannot be loaded.</span>
      </Content>
    </Layout>
)
}

export default NotFoundPage;