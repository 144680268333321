import React from 'react';

function ActionButton({ text, type = 'button', icon= false, onClick = () => {}}) {
  const className = "py-2 px-4 items-center text-sm bg-white text-gray-600 border-gray-300 hover:bg-gray-100 focus:ring-gray-400 block disabled:cursor-not-allowed disabled:opacity-50 border border-transparent rounded-lg shadow-sm      inline-flex justify-center font-medium focus:outline-none focus:ring-offset-2 focus:ring-2";

  return (
    <button
      type={type}
      onClick={onClick}
      className={className}>
      {icon && React.createElement(icon) }
      {text}
    </button>
  );
}

export default ActionButton;