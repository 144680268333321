import StandardForm from "./Forms/StandardForm";
import {toFormFields} from "../lib/functions";
import ProductVariant from "../models/ProductVariant";
import {toast} from "react-toastify";
import {toastWithMessage} from "../lib/toast-helpers";

/**
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProductIdentifiersForm({ productVariant, setIsLoading }) {
  const onSubmit = async (data) => {
    setIsLoading(true);

    return ProductVariant.store.edit(productVariant.id, data).then(() => {
      toastWithMessage(`${ProductVariant.modelName} updated`);
    }).finally(setIsLoading(false))
  };

  return (
    <div>
      <StandardForm formFields={toFormFields(ProductVariant, "product_identifiers")}
                    onSubmit={onSubmit}
                    model={productVariant}
                    topic='product_identifiers'
      />
    </div>
  );
}
//   {/*<header className="flex items-center justify-between">*/}
//         {/*  <div>*/}
//         {/*    <h3 className="text-lg font-medium leading-6 text-gray-900">*/}
//         {/*      Pricing*/}
//         {/*    </h3>*/}
//         {/*  </div>*/}
//         {/*  <div className="flex items-center space-x-2">*/}
//         {/*    <div>*/}
//         {/*      <select className="block w-full py-1 pl-2 pr-8 text-base text-gray-600 bg-gray-100 border-none rounded-md form-select focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">*/}
//         {/*        <option value="1" selected="">USD</option>*/}
//         {/*      </select>*/}
//         {/*    </div>*/}
//         {/*  </div>*/}
//         {/*</header>*/}
//
//     {/*    <div className="grid grid-cols-2 gap-4">*/}
//     {/*      <div>*/}
//     {/*        <div>*/}
//     {/*          <label htmlFor="tax_class" className="flex items-center block text-sm font-medium text-gray-700">*/}
//     {/*            <span className="block"></span>*/}
//     {/*            <span className="block">Tax Class</span>*/}
//     {/*          </label>*/}
//     {/*          <div className="relative mt-1">*/}
//     {/*            <select id="tax_class" className="form-select block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">*/}
//     {/*              <option>Default Tax Class</option>*/}
//     {/*            </select>*/}
//     {/*          </div>*/}
//     {/*        </div>*/}
//     {/*      </div>*/}
//     {/*      <div>*/}
//     {/*        <div>*/}
//     {/*          <label htmlFor="unit_quantity" className="flex items-center block text-sm font-medium text-gray-700">*/}
//     {/*            <span className="block"></span>*/}
//     {/*            <span className="block">Unit quantity</span>*/}
//     {/*          </label>*/}
//     {/*          <div className="relative mt-1">*/}
//     {/*            <input className="form-input block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed" type="number" id="unit_quantity" maxlength="255" />*/}
//     {/*          </div>*/}
//     {/*          <p className="mt-2 text-sm text-gray-500">Enter how many individual units make up this price.</p>*/}
//     {/*        </div>*/}
//     {/*      </div>*/}
//     {/*    </div>*/}
//     {/*    <div className="space-y-4">*/}
//     {/*      <div className="grid grid-cols-2 gap-4">*/}
//     {/*        <div>*/}
//     {/*          <label htmlFor="basePrice" className="flex items-center block text-sm font-medium text-gray-700">*/}
//     {/*            <span className="block"></span>*/}
//     {/*            <span className="block">Base price (excl. Tax)<sup className="text-xs text-red-600">*</sup></span>*/}
//     {/*          </label>*/}
//     {/*          <div className="relative mt-1">*/}
//     {/*            <div>*/}
//     {/*              <div className="relative rounded-md shadow-sm">*/}
//     {/*                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">*/}
//     {/*  <span className="text-gray-500 sm:text-sm">*/}
//     {/*    $*/}
//     {/*  </span>*/}
//     {/*                </div>*/}
//     {/*                <input className="form-input block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed pr-12 pl-7" type="number" step="any"  required="required" maxlength="255"/>*/}
//     {/*                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">*/}
//     {/*  <span className="text-gray-500 sm:text-sm" id="price-currency">*/}
//     {/*    USD*/}
//     {/*  </span>*/}
//     {/*                  </div>*/}
//     {/*              </div>*/}
//     {/*            </div>*/}
//     {/*          </div>*/}
//     {/*          <p className="mt-2 text-sm text-gray-500">The base price for the product, excluding tax</p>*/}
//     {/*        </div>*/}
//
//     {/*        <div>*/}
//     {/*          <label htmlFor="compare_at_price" className="flex items-center block text-sm font-medium text-gray-700">*/}
//     {/*            <span className="block"></span>*/}
//     {/*            <span className="block">Compare at price (excl. tax)</span>*/}
//     {/*          </label>*/}
//     {/*          <div className="relative mt-1">*/}
//     {/*            <div>*/}
//     {/*              <div className="relative rounded-md shadow-sm">*/}
//     {/*                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">*/}
//     {/*  <span className="text-gray-500 sm:text-sm">*/}
//     {/*    $*/}
//     {/*  </span>*/}
//     {/*                </div>*/}
//     {/*                <input className="form-input block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed pr-12 pl-7" type="number" step="any" maxlength="255"/>*/}
//     {/*                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">*/}
//     {/*  <span className="text-gray-500 sm:text-sm" id="price-currency">*/}
//     {/*    USD*/}
//     {/*  </span>*/}
//     {/*                  </div>*/}
//     {/*              </div>*/}
//     {/*            </div>*/}
//     {/*          </div>*/}
//     {/*          <p className="mt-2 text-sm text-gray-500">For display purposes, allows you to show a comparison price, e.g. RRP.</p>*/}
//     {/*        </div>*/}
//     {/*      </div>*/}
//     {/*    </div>*/}
//
//     {/*    <div className="space-y-4">*/}
//     {/*      <div className="flex items-center justify-between">*/}
//     {/*        <div>*/}
//     {/*          <strong>Customer group pricing</strong>*/}
//     {/*          <p className="text-xs text-gray-600">*/}
//     {/*            Determines if you would like different pricing across customer groups.*/}
//
//     {/*          </p></div>*/}
//
//     {/*        <div>*/}
//     {/*          <button>*/}
//     {/*            <input type="checkbox" className="hidden" value="1" />*/}
//     {/*            <span aria-hidden="true" className="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200"></span>*/}
//     {/*          </button>*/}
//     {/*      </div>*/}
//     {/*    </div>*/}
//     {/*  </div>*/}
//     {/*  <div className="flex items-center justify-between pt-4 border-t">*/}
//     {/*    <div>*/}
//     {/*      <strong>Tiered Pricing</strong>*/}
//     {/*      <p className="text-xs text-gray-600">*/}
//     {/*        Tired pricing allows you to offer discounted pricing based on units sold.*/}
//     {/*      </p>*/}
//     {/*    </div>*/}
//     {/*    <button className="py-2 px-3 leading-4 text-sm bg-white text-gray-600 border-gray-300 hover:bg-gray-100 focus:ring-gray-400 block*/}
//     {/*  disabled:cursor-not-allowed disabled:opacity-50*/}
//     {/*  border border-transparent*/}
//     {/*  rounded-lg shadow-sm*/}
//     {/*  inline-flex justify-center font-medium focus:outline-none focus:ring-offset-2 focus:ring-2" type="button">*/}
//     {/*      Add Tier*/}
//     {/*    </button>*/}
//     {/*  </div>*/}
//
//     {/*  <div className="space-y-4">*/}
//     {/*  </div>*/}
//     {/*</div>*/}
//   // );
// // }
